import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Modal, TextField, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { PanelSessionContext } from "../../components/sessionContext";
import { addNotification } from "../global/notificationWrapper";
import axios from "axios";
import { UtilityFunctions } from "../../utils/utilityFunctions";

export const ProfileModals = {
    ChangePassword: () => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);
        const [password, setPassword] = useState("");
        const [confirm, setConfirm] = useState("")

        const submit = () => {
            if (password !== confirm) return addNotification({ severity: "warning", message: "Passwords do not match"})
            if (password.length < 8 || password.length > 128) return addNotification({ severity: "warning", message: "The passwords length needs to be between 8 and 128 characters"});
            
            axios.post(process.env.REACT_APP_API_URL + "/account/profileUpdate", { password }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    addNotification({ severity: "success", message: "Password updated successfully!"});
                } else {
                    console.log("Error deleting asset types ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={() => setOpen(true)} variant="contained">Change Password</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <Typography>
                        Be aware that this can only be undone by a administrator and you could lock your self out of your account. 
                        Above that only choose secure passwords that contain special characters, numbers and are at least
                        8 characters long.
                    </Typography>

                    <Box display="flex" flexDirection="column" gap={2} mt=".5rem">
                        <TextField type="password" value={password} onChange={e => setPassword(e.currentTarget.value.trim())} placeholder="New password" />
                        <TextField type="password" value={confirm} onChange={e => setConfirm(e.currentTarget.value.trim())} placeholder="Confirm new password" />
                    </Box>

                    <Button variant="contained" sx={{ width: "100%", mt: "1rem"}} onClick={submit}>Submit</Button>
                </DialogContent>
            </Dialog>
        </>
    },

    ClearSessions: () => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/account/profileUpdate", { sessionClear: 1 }, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    addNotification({ severity: "success", message: "Cleared all Sessions successfully!"});
                    UtilityFunctions.logout().then(() => window.location.reload());
                } else {
                    console.log("Error deleting asset types ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={() => setOpen(true)} variant="contained">Clear Sessions</Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete all Sessions</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure that you want to delete all session. You will be logged out of your account on every device.
                    </Typography>

                    <ButtonGroup variant="contained" sx={{ mt: "1rem" }}>
                        <Button onClick={submit}>Yes</Button>
                        <Button onClick={e => setOpen(false)}>No</Button>
                    </ButtonGroup>
                </DialogContent>
            </Dialog>
        </>
    }
}
