import { Box, Button, CircularProgress, CssBaseline, ThemeProvider, Typography, colors } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PanelSessionContext, usePanelSession } from "./components/sessionContext";
import AccountsPage from "./scenes/accounts";
import AppsPage from "./scenes/apps";
import AssetsPage from "./scenes/assets";
import AssetTypePage from "./scenes/assettypes";
import PanelAuthentication from "./scenes/authentication/panel";
import { ContainersPage } from "./scenes/containers";
import NotificationWrapper from "./scenes/global/notificationWrapper";
import SidebarComponent from "./scenes/global/sidebar";
import TopBar from "./scenes/global/topbar";
import { ColorModeContext, useMode } from './theme';
import { ConnectORPermissions, ConnectRootPermissions, PROTECTED } from "./utils/types";
import { UtilityFunctions } from "./utils/utilityFunctions";
import { SettingsContext, useSettings } from "./components/settingsContext";
import ProfilePage from "./scenes/profile";
import DashboardPage from "./scenes/dashboard";


function App() {
  const {theme, colorMode} = useMode();
  const panelSession = usePanelSession();
  const settings = useSettings();

  const [initHappen, setInitHappen] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Seanet - Connect";
    if (!initHappen && panelSession.logged === undefined) {
        axios.get(`${process.env.REACT_APP_API_URL}/authentication/fetch`, { withCredentials: true }).then(res => {
          setInitHappen(true);
          if (res.data.success) {
            panelSession.setLogged(res.data.response.user);
            panelSession.setAssetTypes(res.data.response.assetTypes);
            panelSession.setAccounts(res.data.response.accounts);
            panelSession.setAssets(res.data.response.assets);
            panelSession.setContainers(res.data.response.containers);
            panelSession.setApps(res.data.response.apps);
          } else {
            UtilityFunctions.logout();
            console.log("Error fetching user: ", res.data);
          }
        });

        // load settings
        if (localStorage.getItem("devMode") !== null) {
          settings.setDevMode(localStorage.getItem("devMode") === "true");
        }
      } 
  }, []);

  if (panelSession.logged === undefined && !initHappen) {
    return <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress color="inherit" />
        </Box>
      </ThemeProvider>
  </ColorModeContext.Provider>
  }

  return <ColorModeContext.Provider value={colorMode}>
    <PanelSessionContext.Provider value={panelSession}>
      <SettingsContext.Provider value={settings}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationWrapper />

          <div className="app">
            { panelSession.logged?.id === undefined ? <PanelAuthentication /> : <>
              <SidebarComponent />

              <main className="content">
                <TopBar />
                <Routes>
                  <Route path="/" element={< DashboardPage />} />
                  <Route path="/accounts" element={<AccountsPage />} />
                  <Route path="/assettypes" element={<AssetTypePage />} />
                  <Route path="/assets" element={<AssetsPage />} />
                  <Route path="/apps" element={<AppsPage />} />
                  <Route path="/containers" element={<ContainersPage />} />
                  <Route path="/profile" element={<ProfilePage />}/>
                </Routes>
              </main>
            </>}
          </div>
        </ThemeProvider>
      </SettingsContext.Provider>
    </PanelSessionContext.Provider>
  </ColorModeContext.Provider>
}

export default App;
