import { Add, EditNote, Remove } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { TreeItem } from "@mui/x-tree-view";
import axios from "axios";
import { Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as yup from "yup";
import { PanelSessionContext } from "../../components/sessionContext";
import { Container, PROTECTED } from "../../utils/types";
import { addNotification } from "../global/notificationWrapper";

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
});

const getChilds = (childs:any[], id:number) => {
    const fetched = childs.filter(c => c.parent === id);
    return <>
        {fetched.map((c, i) => {
            <TreeItem key={i} nodeId={c.id + ""} label={c.asset_id + ""} children={getChilds(childs, c.id)} />
        })}
    </>
}

export const ContainerDialogs = {
    Create: () => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);

        const submit = (values:Container) => {
            if (session.containers.find(t => t.name === values.name)) return addNotification({severity: "warning", message: "Container with that name already exists"});


            delete values.id;
            axios.post(`${process.env.REACT_APP_API_URL}/container/create`, values, {withCredentials: true}).then(res => {
                if (res.data.success) {
                    const permContainer = session.containers.find(c => c.id === PROTECTED.Container.id);
                    const updated = { ...permContainer, assets: [...permContainer?.assets || [], {id: res.data.response.conn_asset.id}]} as Container 
                    session.setContainers([...session.containers.filter(c => c.id !== PROTECTED.Container.id), updated, res.data.response.container]);
                    session.setAssets([...session.assets, res.data.response.conn_asset]);
                    setOpen(false);
                    addNotification({message: "Container created successfully.", severity: "success"});
                } else {
                    console.error(res.data.error);
                }
            });
        }

        const initialValues:Container = useMemo(() => {return {id: -1, assets: [], apps: [], name: ""}}, []);
        return <>
            <Button onClick={e => setOpen(true)}><Add /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create Container</DialogTitle>
                <DialogContent>
                    <DialogContentText>Fill the form and add assets to your desire to create a access container for accounts to use.</DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                </Box>

                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },
    Delete: ({ids}:{ids:number[]}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/container/delete", {ids}, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    const permContainer:Container = session.containers.find(c => c.id === PROTECTED.Container.id)!;
                    const permAssets = permContainer.assets.filter(a => !res.data.response.includes(a.id!));

                    session.setContainers([...session.containers.filter(c => !ids.includes(c.id!) && c.id !== PROTECTED.Container.id), {...permContainer, assets: permAssets}]);
                    session.setAssets(session.assets.filter(a => !res.data.response.includes(a.id!)))
                    addNotification({message: "Container deleted", severity: "success"});
                } else {
                    console.log("Error deleting container", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)} variant="contained"><Remove /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete a Container</DialogTitle>
                <DialogContent>
                    <DialogContentText>Be aware that the deletion of an container remove all permissions of accounts associated with that asset! You are about to delete {ids.length} Container/s.</DialogContentText>
                    <Box display="flex" justifyContent="center" mt="20px" gap="20px">
                        <Button variant="contained" onClick={submit}>Yes</Button>
                        <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    },
    Edit: ({id}:{id:number}) => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);

        const submit = (values:Container) => {
            axios.post(`${process.env.REACT_APP_API_URL}/container/edit`, values, {withCredentials: true}).then(res => {
                if (res.data.success) {
                    const container = { ...values}
                    session.setContainers([...session.containers.filter(c => c.id !== id), container]);
                    setOpen(false);
                    addNotification({message: "Container edited successfully.", severity: "success"});
                } else {
                    console.error(res.data.error);
                }
            });
        }

        const initialValues:Container|undefined = useMemo(() => session.containers.find(c => c.id === id), [id]);
        
        if (initialValues === undefined) return <></>;
        return <>
            <Button onClick={e => setOpen(true)}><EditNote /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Edit Container</DialogTitle>
                <DialogContent>
                    <DialogContentText>Fill the form and add assets to your desire to create a access container for accounts to use.</DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                </Box>

                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },
}