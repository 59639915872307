import { ArrowLeft } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, Grid, List, ListItemButton, Switch, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { PanelSessionContext } from "../../../components/sessionContext";
import { ConnectRootPermissions } from "../../../utils/types";
import { addNotification } from "../../global/notificationWrapper";
import { AppPermConfigurator } from "./appPermConfigurator";
import { ContainerDetail } from "./containerDetail";
import { AccessContainerSelection } from "./containerSelection";


export const AccessCard:FC = () => {
    const session = useContext(PanelSessionContext);

    const [stage, setStage] = useState<number>(0);
    const [appMode, setAppMode] = useState<number>(0);
    const [account, setAccount] = useState<number>(0);

    const [editedAccounts, setEditedAccounts] = useState<number[]>([]);
    const [selectedContainer, setSelectedContainer] = useState<number>();

    const updateContainer = (container:number) => {
        setSelectedContainer(container);
        setStage(1);
        setEditedAccounts([...editedAccounts, account]);
    }

    const save = () => {
        // remove duplicates
        const clearedAccounts = editedAccounts.filter((v, i, a) => a.indexOf(v) === i);
        if (clearedAccounts.length == 0) {
            addNotification({ severity: "warning", message: "There is no account that needs to be saved!"});
        }
        
        for (const acc of clearedAccounts) {
            const account = session.accounts.find(a => a.id === acc);
            if (!account) continue;

            axios.post(`${process.env.REACT_APP_API_URL}/account/edit`, {...account, password: ""}, {withCredentials: true}).then(res => {
                if (res.data.success) {
                    addNotification({message: "Saved " + account.name, severity: "success"});
                } else {
                    console.log(res.data);
                    addNotification({message: "Failed to save " + account.name, severity: "error"});
                }
            })
        }
        setEditedAccounts([]);
    }

    useEffect(() => {
        const container = session.containers.find(c => c.id === selectedContainer);
        if (container === undefined) {
            return;
        }

    }, [selectedContainer]);

    const stages = [
        appMode && editedAccounts.length > 0 ? <><Button variant="contained" onClick={save}>Save</Button> <AppPermConfigurator acc={account} editAccount={(acc) => setEditedAccounts([...editedAccounts, acc])} /></> : <AccessContainerSelection account={account} cb={updateContainer} />,
        <ContainerDetail acc={account} con={selectedContainer || 0} editAccount={(acc) => setEditedAccounts([...editedAccounts, acc])}/>
    ];
    return <Grid container spacing={2} minHeight={"500px"} maxHeight={"1000px"} overflow={"auto"}>
        <Grid item xs={3}>
            <Card sx={{p: "1rem", height: "100%"}}>
                <Box display="flex" gap={2} alignItems={"center"}>
                    <Typography variant="h3">Accounts</Typography>
                    {session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident) ? 
                        <Tooltip title="Set root permissions for account">
                            <Switch checked={!!appMode} onChange={(e, v) => setAppMode(v === true ? 1 : 0)} /> 
                        </Tooltip>
                    : undefined}
                </Box>
                <TextField label="Search" variant="outlined" fullWidth sx={{mt: "10px", borderRadius: "15px"}} />
                <List sx={{"& .Mui-selected": {
                    backgroundColor: "rgba(20, 27, 45, .7) !important",
                }}}>
                    {session.accounts.map((acc, index) => {
                        return <ListItemButton key={index} sx={{ borderRadius: "15px" }} selected={acc.id == account} onClick={() => setAccount(acc.id)}>
                            {acc.name}
                        </ListItemButton>
                    })}
                </List>
            </Card>
        </Grid>
        <Grid item xs={9}>
            <Card sx={{p: "1rem", height: "100%"}}>
                {stage > 0 && <ButtonGroup variant="contained" sx={{mb: "20px"}}>
                    <Button onClick={e => setStage(stage - 1)}><ArrowLeft /></Button>    
                    {editedAccounts.length > 0 && <Button onClick={save}>Save</Button>}    
                </ButtonGroup>}
                {account ? stages[stage] : <Typography variant="h6">Select an account to manage access.</Typography>}
            </Card>
        </Grid>
    </Grid>
}