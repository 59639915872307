import { useContext, useMemo } from "react";
import { PanelSessionContext } from "../../components/sessionContext";
import { getColors } from "../../theme";
import { Box, Card, Chip, Grid, Typography, useTheme } from "@mui/material";
import { App, AppWebFetch, Session } from "../../utils/types";
import { Circle } from "../../components/misc/shapes";
import { UtilityFunctions } from "../../utils/utilityFunctions";
import moment from "moment";
import { BarChart, SentimentDissatisfiedOutlined, SettingsInputAntenna } from "@mui/icons-material";

const GlobalStats = () => {
    const session = useContext(PanelSessionContext)
    const theme = useTheme();
    const colors = getColors(theme.palette.mode)

    const inactiveApps = useMemo(() => {
        let inactive:App[] = [];
        for (const app of session.apps) {
            const fetch = app.webFetch as AppWebFetch;
            if (fetch && fetch.enabled) {
                if (!fetch.success) inactive.push(app);
            }
        }
        return inactive;
    }, [])
    const allSessions = useMemo(() => session.accounts.flatMap(acc => acc.sessions), []);
    const recentSessions = useMemo(() => {
        let today = 0;
        let month = 0;
        const todayDate = moment().subtract(1, "day").unix();
        const monthDate = todayDate - (60 * 60 * 24 * 30);

        for (const ses of allSessions) {
            if (ses.created >= todayDate) {
                today++;
                month++;
            } else if (ses.created >= monthDate) {
                month++;
            }
        }
        return {today, month};
    }, []);

    return <Grid container spacing={2} width="100%">
        <Grid item xs={12} md={4}>
            <Card sx={{ width: "100%", minHeight: "250px", height: "100%", p: "1.5rem"}}>
                <Box display="flex" height={"100%"} flexDirection="column" justifyContent="space-between">
                    <Box>
                        <Box display="flex" gap={1}>
                            <Circle sx={{mt: "7px"}} color={inactiveApps.length > 0 ? colors.redAccent[700] : colors.greenAccent[700]} size="15px" animation={inactiveApps.length > 0} />
                            <Box display="flex" flexDirection="column">
                                <Typography variant="h3" fontWeight={700}>{inactiveApps.length === 0 ? "All Apps Online" : "App Inactivity Detected"}</Typography>
                                <Typography color={colors.gray[100]}>{inactiveApps.length > 0 ? `Connect couldn't fetch data for ${inactiveApps.length} App/s` : `${session.apps.length} App/s up and running`}</Typography>
                            </Box>
                        </Box>

                        {inactiveApps.length > 0 ?
                            <Box display="flex" my="1rem" flexWrap="wrap" gap={2}>{inactiveApps.map((a, i) => <Chip key={i} label={a.name} />)}</Box> 
                        : 
                            <Box display="flex" my="1rem" flexWrap="wrap" gap={1}>{session.apps.filter(a => (a.webFetch as AppWebFetch).enabled).map((a, i) => <Chip key={i} color="success" label={a.name} />)}</Box>
                        }
                    </Box>

                    {inactiveApps.length > 0 ?
                        <Typography color={colors.gray[100]}>
                            Tried to connect {UtilityFunctions.formatUnixTimeStamp(moment().unix() - (inactiveApps[0].webFetch as AppWebFetch).lastFetch)} hours ago. <br/>
                            Please contact a system administrator to resolve the issue.
                        </Typography> : session.apps.some(a => (a.webFetch as AppWebFetch).enabled) && <Typography color={colors.gray[100]}>
                            Last Check was performed {UtilityFunctions.formatUnixTimeStamp(moment().unix() - ((session.apps.filter(a => (a.webFetch as AppWebFetch).enabled)[0].webFetch as AppWebFetch).lastFetch || moment().unix()))} hours ago. <br/>
                        </Typography>
                    }
                </Box>
                
            </Card>
        </Grid>
        <Grid item xs={12} md={4}>
            <Card sx={{ width: "100%", minHeight: "250px", p: "1.5rem"}}>
                <Box>
                    <Box display="flex" gap={1}>
                        <BarChart sx={{mt: "7px", color: colors.greenAccent[700]}}  />
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h3" fontWeight={700}>Active Sessions</Typography>
                            <Typography color={colors.gray[100]}>{allSessions.length} Sessions active from {session.accounts.length} Accounts</Typography>
                        </Box>
                    </Box>
                    <Typography mt="2rem" width="100%" align="center">Created Sessions</Typography>
                    <Box display="flex" width="100%" justifyContent="center"  alignItems="center" gap={4}>
                        <Box>
                            <Typography align="right" variant="h2" fontWeight={700}>{recentSessions.today}</Typography>
                            <Typography align="right">Last 24 hours</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h2" fontWeight={700}>{recentSessions.month}</Typography>
                            <Typography>Last 30 days</Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
        <Grid item xs={12} md={4}>
            <Card sx={{ width: "100%", minHeight: "250px", p: "1.5rem"}}>
                <Box>
                    <Box display="flex" gap={1}>
                        <SettingsInputAntenna sx={{mt: "7px", color: colors.greenAccent[700]}}  />
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h3" fontWeight={700}>API Usage</Typography>
                            <Typography color={colors.gray[100]}>All apps calculated together</Typography>
                        </Box>
                    </Box>

                    <Typography mt="2rem" width="100%" align="center">Total Hits</Typography>
                    <Box display="flex" width="100%" justifyContent="center" alignItems="center" gap={4}>
                        <Box>
                            <Typography align="right" variant="h2" fontWeight={700}>675</Typography>
                            <Typography align="right">Last 24 hours</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h2" fontWeight={700}>7534</Typography>
                            <Typography>Last 30 days</Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Grid>
    </Grid>
}

export default GlobalStats;