import { Add, EditNote, Remove } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, Switch, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as yup from "yup";
import { PanelSessionContext } from "../../components/sessionContext";
import { App, AppPermissions, AppWebFetch, Permission } from "../../utils/types";
import { addNotification } from "../global/notificationWrapper";


const validationSchema = yup.object().shape({
    name: yup.string().max(50, "Name too long").min(5, "Name too short").required(),
    webFetch: yup.boolean(),
    url: yup.string().required().url("Invalid Url")
});

const permColumns = [
    { field: "ident", headerName: "Identifier", valueGetter: (params:any) => params.row.p.ident, flex: .5, editable: true },
    { field: "name", headerName: "Display name", valueGetter: (params:any) => params.row.p.name, flex: .5, editable: true },
    { field: "type", headerName: "Type", type: "singleSelect", valueOptions: ["Object-Related", "Container", "Root"], editable: true }
]
let counter = 1;

export const AppDialogs = {
    Create: () => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState<boolean>(false);
        const [perms, setPerms] = useState<{id:number, type:string, p:Permission}[]>([]);
        const [selection, setSelection] = useState<number[]>([]);

        const submit = (values:any) => {
            const or = perms.filter(p => p.type === "Object-Related").map(p => p.p);
            const root = perms.filter(p => p.type === "Root").map(p => p.p);
            const con = perms.filter(p => p.type === "Container").map(p => p.p);

            axios.post(process.env.REACT_APP_API_URL + "/apps/create", {...values, permissions: { or, root, con }}, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    addNotification({ message: "App created!", severity: "success"});
                    session.setApps([...session.apps, res.data.response]);
                } else {
                    addNotification({ message: "Failed to create App!", severity: "error"});
                    console.log(res.data);
                }
            })
            setPerms([]);
        }

        const addPermission = () => {
            setPerms([...perms, { id: counter++,  type: "Root", p: { ident: "app.ident.template.perm", name: "Template"}}])
        }

        const initialValues:App = { id: -1, name: "", permissions: { or: [], root: [], con: [] }, token: "", url: "", webFetch: false};
        return <>
            <Button onClick={e => setOpen(true)}><Add /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create an app!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill out the form to create a new app
                    </DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}:any) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <TextField variant="filled" type="text" label="URL" onBlur={handleBlur} onChange={handleChange}
                                        value={values.url} name="url" error={!!touched.url && !!errors.url} helperText={touched.url && errors.url}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">Fetch permissions from URL {touched.url && errors.url}</Typography>
                                        <Switch name="webFetch" id="webFetch" checked={values.webFetch || false} onChange={(e, c) => handleChange({...e, target: { name: "webFetch", value: c}}) } />
                                    </Box>
                                </Box>


                                {!values.webFetch && <Box minHeight="20rem" pt="20px">
                                    <ButtonGroup sx={{mb: "20px"}} variant="contained">
                                        <Button onClick={addPermission}><Add /></Button>
                                        <Button onClick={e => setPerms(perms.filter(p => !selection.includes(p.id)))} ><Remove /></Button>
                                    </ButtonGroup>
                                    <DataGrid processRowUpdate={(newRow, oldRow) => {
                                        
                                        newRow.p.ident = (newRow as any).ident;
                                        newRow.p.name = (newRow as any).name;
                                        delete (newRow as any).ident;
                                        delete (newRow as any).name;
                                        setPerms([...perms.filter(p => p.id !== oldRow.id), newRow]);
                                        return newRow;
                                    }} columns={permColumns} rows={perms} rowSelectionModel={selection} editMode="row" onRowSelectionModelChange={e => setSelection(e as number[])} checkboxSelection />
                                </Box>}

                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },
    Delete: ({ids}:{ids:number[]}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/apps/delete", {ids}, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    session.setApps(session.apps.filter(a => !ids.includes(a.id)));
                    addNotification({message: "Apps deleted", severity: "success"});
                } else {
                    console.log("Error deleting apps ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)} variant="contained"><Remove /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete App/s</DialogTitle>
                <DialogContent>
                    <DialogContentText>Be aware that the deletion of an app will lead to the deletion of all the configurations! You are about to delete {ids.length} App/s.</DialogContentText>
                    <Box display="flex" justifyContent="center" mt="20px" gap="20px">
                        <Button variant="contained" onClick={submit}>Yes</Button>
                        <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    },
    Edit: ({id}:{id:number}) => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState<boolean>(false);
        const [perms, setPerms] = useState<{id:number, type:string, p:Permission}[]>([]);
        const [selection, setSelection] = useState<number[]>([]);
        const [initialValues, setInitValues] = useState<App>(session.apps.find(a => a.id === id)!);

        const submit = (values:any) => {
            const or = perms.filter(p => p.type === "Object-Related").map(p => p.p);
            const root = perms.filter(p => p.type === "Root").map(p => p.p);
            const con = perms.filter(p => p.type === "Container").map(p => p.p);
            
            axios.post(process.env.REACT_APP_API_URL + "/apps/edit", {...values, permissions: { or, root, con }}, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    addNotification({ message: "App edited!", severity: "success"});
                    session.setApps([...session.apps.filter(a => a.id !== id), res.data.response]);
                } else {
                    addNotification({ message: "Failed to edit App!", severity: "error"});
                    console.log(res.data);
                }
                setPerms([]);
            })
        }

        const addPermission = () => {
            setPerms([...perms, { id: counter++,  type: "Root", p: { ident: "app.ident.template.perm", name: "Template"}}])
        }

        const openDialog = () => {
            const app = session.apps.find(a => a.id === id)!
            const cache = {...app, webFetch: (app.webFetch as AppWebFetch).enabled};
            setInitValues(cache);

            setPerms([
                ...cache.permissions.or.map(p => {return { id: counter++, type: "Object-Realted", p }}), 
                ...cache.permissions.root.map(p => {return { id: counter++, type: "Root", p }}),
                ...cache.permissions.con.map(p => {return { id: counter++, type: "Container", p }})
            ])

            console.log([
                ...cache.permissions.or.map(p => {return { id: counter++, type: "Object-Realted", p }}), 
                ...cache.permissions.root.map(p => {return { id: counter++, type: "Root", p }}),
                ...cache.permissions.con.map(p => {return { id: counter++, type: "Container", p }})
            ]);
            setOpen(true);
        }

        return <>
            <Button onClick={openDialog}><EditNote /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create an app!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill out the form to create a new app
                    </DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}:any) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <TextField variant="filled" type="text" label="URL" onBlur={handleBlur} onChange={handleChange}
                                        value={values.url} name="url" error={!!touched.url && !!errors.url} helperText={touched.url && errors.url}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">Fetch permissions from URL {touched.url && errors.url}</Typography>
                                        <Switch name="webFetch" id="webFetch" checked={values.webFetch || false} onChange={(e, c) => handleChange({...e, target: { name: "webFetch", value: c}}) } />
                                    </Box>
                                </Box>


                                {!values.webFetch && <Box minHeight="20rem" pt="20px">
                                    <ButtonGroup sx={{mb: "20px"}} variant="contained">
                                        <Button onClick={addPermission}><Add /></Button>
                                        <Button onClick={e => setPerms(perms.filter(p => !selection.includes(p.id)))} ><Remove /></Button>
                                    </ButtonGroup>
                                    <DataGrid processRowUpdate={(newRow, oldRow) => {
                                        
                                        newRow.p.ident = (newRow as any).ident;
                                        newRow.p.name = (newRow as any).name;
                                        delete (newRow as any).ident;
                                        delete (newRow as any).name;
                                        setPerms([...perms.filter(p => p.id !== oldRow.id), newRow]);
                                        return newRow;
                                    }} columns={permColumns} rows={perms} rowSelectionModel={selection} editMode="row" onRowSelectionModelChange={e => setSelection(e as number[])} checkboxSelection />

                                </Box>}

                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    }
}