import axios from "axios";
import Cookies from "js-cookie";
import { PanelSession } from "../components/sessionContext";
import { Asset } from "./types";

export const UtilityFunctions = {
    logout: async () => {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/logout`, {}, { withCredentials: true })
        console.log(res.data);
        if (res.data.success) {
            window.location.reload();
        }
    },
    redirectToExternalURL: (url:string, data:any) => {
        // Create a form element
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url;
        form.style.display = 'none';
      
        // Append input fields for each data key-value pair
        Object.keys(data).forEach((key) => {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = data[key];
          form.appendChild(input);
        });
      
        // Append the form to the body and submit it
        document.body.appendChild(form);
        form.submit();
    },
    formatUnixTimeStamp: (seconds:number) => {
      // Calculate hours, minutes and remaining seconds
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
  
      // Pad single digit minutes and seconds with a leading zero
      const paddedHours = String(hours).padStart(2, '0');
      const paddedMinutes = String(minutes).padStart(2, '0');
      const paddedSeconds = String(remainingSeconds).padStart(2, '0');
  
      // Return formatted time string
      return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  }
      
}