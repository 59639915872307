import { Box, ButtonGroup, Card, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import Header from "../../components/misc/header";
import TableWrapper from "../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../components/sessionContext";
import { UtilityFunctions } from "../../utils/utilityFunctions";
import { ContainerDialogs } from "./containerDialogs";
import ContainerEditor from "./containerEditor";
import { PROTECTED } from "../../utils/types";
import { SettingsContext } from "../../components/settingsContext";

export const ContainersPage:FC = () => {
    const session = useContext(PanelSessionContext);
    const settings = useContext(SettingsContext);

    const [selection, setSelection] = useState<number[]>([]);
    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "assets", headerName: "Asset/s", flex: .5, valueGetter: (params:any) => params.row.assets.length },
        { field: "apps", headerName: "App/s", flex: .5, valueGetter: (params:any) => params.row.apps.length}
    ];

    const displayedContainers = useMemo(() => settings.devMode ? session.containers : session.containers.filter(c => c.id !== PROTECTED.Container.id), [settings.devMode, session.containers]);
    return <Box m="20px">
        <Header title="Containers" subtitle="Manage your containers here." />
        <Card sx={{ p: "20px" }}>
            <Grid container spacing={2}>
                <Grid item xs={selection.length === 1 ? 6 : 12}>
                    <Box>
                        <ButtonGroup variant="contained" sx={{ mb: ".9rem"}} disabled={selection.includes(PROTECTED.Container.id)}>
                            <ContainerDialogs.Create />
                            { selection.length === 1 && <ContainerDialogs.Edit id={selection[0]} /> }
                            { selection.length > 0 && <ContainerDialogs.Delete ids={selection} /> }
                        </ButtonGroup>
                        <TableWrapper>
                            <DataGrid checkboxSelection rows={displayedContainers} columns={columns} rowSelectionModel={selection} onRowSelectionModelChange={e => setSelection(e as number[])}  />
                        </TableWrapper>
                    </Box>
                </Grid>
                {selection?.length === 1 && <Grid item xs={6}>
                    <ContainerEditor id={selection[0]} />
                </Grid>}
            </Grid>
        </Card>
    </Box>

}