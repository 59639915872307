import { Check, Close } from "@mui/icons-material";
import { Box, ButtonGroup, Card, Menu, MenuItem, Select } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useMemo, useState } from "react";
import Header from "../../components/misc/header";
import TableWrapper from "../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../components/sessionContext";
import { FieldTypes, PROTECTED } from "../../utils/types";
import { AssetDialog } from "./assetDialogs";
import { SettingsContext } from "../../components/settingsContext";

const AssetsPage = () => {
    const session = useContext(PanelSessionContext);
    const settings = useContext(SettingsContext);

    const [selectedType, setSelectedType] = useState<number>(session.assetTypes.length < 2 ? -1 : session.assetTypes[1].id);
    const [selection, setSelection] = useState<number[]>([]);

    const forbidden = selectedType == session.assetTypes.find(type => type.name === "Container")!.id;
    const columns = useMemo(() => {
        if (selectedType === -1) return [];
        const type = session.assetTypes.find(type => type.id === selectedType);
        if (type === undefined) return [];
        return [
            { field: "id", headerName: "ID" },
            { field: "name", headerName: "Name", flex: 1 }, 
            ...type.fields.map((field, index) => {
            return { field: field.id + "", headerName: field.name, flex: .5, renderCell: field.type === FieldTypes.CHECKFIELD.id ? (params:any) => {
                return params.row.fields[field.id!] ? <Check /> : <Close />;
            }  : field.type === FieldTypes.ASSET.id ? (params:any) => {
                return (session.assets.find(a => a.id === params.row.fields[field.id!]) || { name: "-" }).name
            } : (params:any) => {
                return params.row.fields[field.id!]
            }}
        })];
    }, [selectedType]);    

    // useEffect(() => {
    //     UtilityFunctions.getAssets(selectedType, session);
    // }, [selectedType]);

    return <Box m="20px">
        <Header title="Assets" subtitle="Manage your assets here." />
        <Card sx={{p: "20px"}}>
            <Box display="flex" gap={2}>
                {!(forbidden || selectedType == -1) && <ButtonGroup variant="contained">
                    <AssetDialog.Create type={selectedType} />
                    {selection.length === 1 && <AssetDialog.Edit id={selection[0]} />}
                    {selection.length > 0 && <AssetDialog.Delete ids={selection} />}
                </ButtonGroup>}
                <Select onChange={e => setSelectedType(+e.target.value)} value={selectedType}>
                    <MenuItem value={-1}>-</MenuItem>
                    {session.assetTypes.map((type) => {
                        if (!settings.devMode && type.id === PROTECTED.AssetType.id) return null;
                        return <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                    })}
                </Select>
            </Box>
            <TableWrapper> 
                <DataGrid rowSelectionModel={selection} onRowSelectionModelChange={e => setSelection(e as number[])} rows={session.assets.filter(a => a.typeId === selectedType) || []} columns={columns} checkboxSelection />
            </TableWrapper>
        </Card>
    </Box>
}

export default AssetsPage;