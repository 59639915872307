import { Box, CssBaselineProps, Fade, SxProps } from "@mui/material"
import { setIn } from "formik";
import { useEffect, useState } from "react"

export const Circle = ({size, color, animation, sx}:{size?:string, color:string, animation?:boolean, sx?:SxProps}) => {
    const css = {
        width: size || "25px",
        height: size || "25px",
        borderRadius: "50%",
        background: color,
        ...sx
    } as SxProps;

    const [appear, setAppear] = useState<boolean>(true);

    useEffect(() => {
        let id = undefined as any;
        let localAppear = true;

        if (animation) {
            id = setInterval(() => {
                setAppear(!localAppear);
                localAppear = !localAppear;
            }, 1000);
        }
        return () => {
            if (animation) clearInterval(id);
        }
    }, [animation])

    return <>
        <Fade in={appear}>
            <Box sx={css} />
        </Fade>
    </>
}