import { Add, EditNote, Remove } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as yup from "yup";
import { PanelSession, PanelSessionContext } from "../../components/sessionContext";
import { AssetType, Field, FieldTypes } from "../../utils/types";
import { addNotification } from "../global/notificationWrapper";

const validationSchema = yup.object().shape({
    name: yup.string().max(50, "Name can't be longer than 50 characters!").required("Name is required"),
    fields: yup.array().of(yup.object().shape({
        name: yup.string().max(50, "Name can't be longer than 50 characters!").required("Name is required"),
        type: yup.number().required("Type is required"),
    }))
});

const fieldColumns = (fields:Field[], setFields:(fields:Field[]) => void, session:PanelSession) => [
    { field: "name", headerName: "Name", type: "string", editable: true},
    { field: "type", headerName: "Type", type: "singleSelect", valueOptions: Object.entries(FieldTypes).map(([key, value]) => { return {value: value.id, label: value.name}}), editable: true},
    { field: "options", headerName: "Options", type: "singleSelect", renderCell: (params:any) => {
        if (params.row.type === FieldTypes.ASSET.id) {
            return <Select value={params.row.options || -1} onChange={e => {
                params.row.options = e.target.value;
                setFields(fields.map(f => f.id === params.row.id ? params.row : f));    
                }}>
                <MenuItem value={-1}>-</MenuItem>
                {session.assetTypes.map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
            </Select>
        }
        return <></>
    }},
    { field: "required", headerName: "Required", type: "boolean", editable: true },
]

const permissionColumns = [
    { field: "id", headerName: "ID"},
    { field: "name", headerName: "Name", type: "string", editable: true},
]

export const AssetTypeDialogs = {
    Create: () => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);
        const [selection, setSelection] = useState<number[]>([]);

        const submit = (values:AssetType) => {
            if (session.assetTypes.find(t => t.name === values.name)) return addNotification({severity: "warning", message: "Type with that name already exists"});

            axios.post(process.env.REACT_APP_API_URL + "/assetType/create", {...values, fields: []}, { withCredentials: true }).then(response => {
                if (response.data.success) {
                    setOpen(false);
                    session.setAssetTypes([...session.assetTypes, response.data.response]);
                    addNotification({message: "AssetType created", severity: "success"});
                } else {
                    console.log("Error creating service ", response.data.error);
                }
            });
        }

        const intialValues = { id: -1, name: "", permissions: [], fields: [] };
        return <div>
            <Button variant='contained' onClick={() => setOpen(true)}><Add /></Button>
            <Dialog fullWidth onClose={() => setOpen(false)} open={open}>
                <DialogTitle>Create a new Asset-Type</DialogTitle>
                <DialogContent>
                    <DialogContentText>Fill the form to create a new service.</DialogContentText>
                        <Formik onSubmit={submit} initialValues={intialValues} validationSchema={validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="flex" flexDirection="column" m="0 1rem">
                                        <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                            value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                            sx={{borderRadius: "10px", margin: "10px 0"}} />
                                        
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt="20px">
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </Box>
                                </form>    
                            )}
                        </Formik>
                </DialogContent>
            </Dialog>
        </div>
    },
    Delete: ({ids}:{ids:number[]}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/assetType/delete", {ids}, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    session.setAssetTypes(session.assetTypes.filter(s => !ids.includes(s.id)));
                    addNotification({message: "Asset types deleted", severity: "success"});
                } else {
                    console.log("Error deleting asset types ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)} variant="contained"><Remove /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete a Asset-Type</DialogTitle>
                <DialogContent>
                    <DialogContentText>Be aware that the deletion of an asset type will lead to a deletion of all its assets! You are about to delete {ids.length} AssetType/s.</DialogContentText>
                    <Box display="flex" justifyContent="center" mt="20px" gap="20px">
                        <Button variant="contained" onClick={submit}>Yes</Button>
                        <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    },
    Edit: ({id}:{id:number}) => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState(false);
        const [intialValues, setIntialValues] = useState<AssetType>({id: -1, name: "", fields: []});

        const [selection, setSelection] = useState<number[]>([]);
        const [fields, setFields] = useState<Field[]>([]);
        const fieldId = useMemo(() => (fields.map(f => f.id).sort((a, b) => b! - a!)[0] || 0) + 1, [fields]);

        const submit = (values:AssetType) => {
            const newFields = fields.map(f => {
                if (intialValues.fields.find(i => i.id === f.id)) return f;
                delete f.id;
                return f;
            });
            
            axios.post(process.env.REACT_APP_API_URL + "/assetType/edit", {...values, fields: newFields}, { withCredentials: true }).then(response => {
                if (response.data.success) {
                    setOpen(false);
                    session.setAssetTypes(session.assetTypes.map(s => {
                        if (s.id === values.id) return response.data.response;
                        return s;
                    }));
                    addNotification({message: "Service edited", severity: "success"});
                } else {
                    console.log("Error editing service " + response.data.error);
                }
            });
        }

        const openDialog = () => {
            const assetType = session.assetTypes.find(s => s.id === id);
            if (assetType) {
                setIntialValues(assetType);
                setFields(assetType.fields);
                setOpen(true);
            }
        }

        return <>
            <Button onClick={openDialog} variant="contained"><EditNote /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>Change the form where you think changes have to be made. </DialogContentText>
                    <Formik onSubmit={submit} initialValues={intialValues} validationSchema={validationSchema}>
                            {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="flex" flexDirection="column" m="0 1rem">
                                        <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                            value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                            sx={{borderRadius: "10px", margin: "10px 0"}} />

                                        <Box minHeight="20rem" mt="20px">
                                            <Typography variant="h5">Fields</Typography>
                                            <ButtonGroup variant="contained" sx={{mb: "10px", width: "fit-content"}}>
                                                <Button onClick={() => setFields([...fields, {id: fieldId, name: "New Field", type: FieldTypes.TEXT.id, required: false}])}><Add /></Button>
                                                <Button onClick={() => setFields(fields.filter(f => !selection.includes(f.id!)))}><Remove /></Button>
                                            </ButtonGroup>
                                            <DataGrid sx={{minHeight: "200px", maxHeight: "60%", overflow: "auto"}} rows={fields} processRowUpdate={(newRow, oldRow) => {
                                                setFields(fields.map(f => f.id === oldRow.id ? newRow : f));
                                                return newRow;
                                            }} columns={fieldColumns(fields, setFields, session)} checkboxSelection onRowSelectionModelChange={e => setSelection(e as number[])} rowSelectionModel={selection}/>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt="20px">
                                        <Button type="submit" variant="contained">Submit</Button>
                                    </Box>
                                </form>    
                            )}
                        </Formik>
                </DialogContent>
            </Dialog>
        </>
    }
}