import { Box, SxProps, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { getColors } from "../../theme";

const TabsWrapper:FC<{children:ReactElement[]|ReactElement, sx?:SxProps}> = ({children, sx}:{sx?:SxProps, children:ReactElement[]|ReactElement}) => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return <Box sx={{
        "& .css-17ma8k9-MuiButtonBase-root-MuiTab-root": {
            color: "#c1c1c1",
        },
        "& .css-17ma8k9-MuiButtonBase-root-MuiTab-root.Mui-selected": {
            color: "#8f8f8f",
            // background: "#252d41",
            borderRadius: "10px"
        }
    }}>
        {children}
    </Box>
}

export default TabsWrapper;