import { Box, ButtonGroup, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useMemo, useState } from "react";
import Header from "../../components/misc/header";
import TableWrapper from "../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../components/sessionContext";
import { PROTECTED } from "../../utils/types";
import { AppDialogs } from "./appDialogs";
import { SettingsContext } from "../../components/settingsContext";

const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name" },
    { field: "url", headerName: "URL", flex: .5 },
    { field: "token", headerName: "Token", flex: .5},
    { field: "or", headerName: "Object Related", valueGetter: (params:any) => { return params.row.permissions.or.length + " Permission/s";}},
    { field: "container", headerName: "Container", valueGetter: (params:any) => { return params.row.permissions.con.length + " Permission/s";}},
    { field: "root", headerName: "Root", valueGetter: (params:any) => { return params.row.permissions.root.length + " Permission/s";}}
]

const AppsPage = () => {
    const session = useContext(PanelSessionContext);
    const settings = useContext(SettingsContext);

    const [selection, setSelection] = useState<number[]>([]);

    const displayedApps = useMemo(() => settings.devMode ? session.apps : session.apps.filter(a => a.id !== PROTECTED.App.id), [session.apps, settings.devMode]);
    return <Box m="20px">
        <Header title="Apps" subtitle="Manage all apps here"/>
        <Card sx={{p: "20px"}}>
            <ButtonGroup variant="contained" disabled={selection.includes(PROTECTED.App.id)}>
                <AppDialogs.Create />
                {selection.length === 1 ? <AppDialogs.Edit id={selection[0]} /> : undefined}
                {selection.length > 0 ? <AppDialogs.Delete ids={selection} /> : undefined}
            </ButtonGroup>
            <TableWrapper>
                <DataGrid rowSelectionModel={selection} onRowSelectionModelChange={e => setSelection(e as number[])} rows={displayedApps} columns={columns} checkboxSelection />
            </TableWrapper>
        </Card>
    </Box>

}

export default AppsPage;