import { ArrowRight, Check, Close } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { FC, useContext, useMemo } from "react";
import TableWrapper from "../../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../../components/sessionContext";
import { getColors } from "../../../theme";
import { ConnectORPermissions, ConnectRootPermissions, PROTECTED } from "../../../utils/types";

export const AccessContainerSelection:FC<{account:number, cb:(container:number) => void}> = ({account, cb}:{account:number, cb:(container:number) => void}) => {
    const session = useContext(PanelSessionContext);
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    const acc = useMemo(() => session.accounts.find(a => a.id === account), [account, session.accounts]);
    const columns = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "accessable", headerName: "Accessable", renderCell: (params:any) => {
            const containerAsset = session.assets.find(a => a.typeId === PROTECTED.AssetType.id && a.fields['0'] === params.row.id)
            if (acc?.access.find(a => a.containerId === PROTECTED.Container.id)?.permissions.find(p => p.asset === containerAsset?.id)?.ident.includes(ConnectORPermissions.CONTAINER_ADMIN.ident) || acc?.permissions.includes(ConnectRootPermissions.ROOT.ident)) {
                return <Check htmlColor={colors.greenAccent[700] + ""} />
            } else {
                return <Close  />
            }
        }},
        { field: "actions", headerName: "Actions", renderCell: (params:any) => {
            return <Button onClick={e => cb(params.row.id)} variant="contained"><ArrowRight /></Button>
        }}
    ];

    return <Box>
        <Typography variant="h3">Container</Typography>
        <TableWrapper>
            <DataGrid rows={session.containers} columns={columns} sortModel={[{ field: "id", sort: "asc"}]} hideFooter/>
        </TableWrapper>
    </Box>
}