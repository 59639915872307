import { Box, Switch, Typography, useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { PanelSessionContext } from "../../../components/sessionContext";
import { getColors } from "../../../theme";
import { PROTECTED } from "../../../utils/types";

export const AppPermConfigurator = ({acc, editAccount}:{acc:number, editAccount:(acc:number) => void}) => {
    const session = useContext(PanelSessionContext);
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    
    const account = useMemo(() => session.accounts.find(a => a.id === acc), [acc]);

    const updatePermission = (ident:string) => {
        if (!account) return;
        if (account.permissions.includes(ident)) {
            account.permissions = account.permissions.filter(i => i !== ident);
        } else {
            account.permissions.push(ident);
        }
        session.setAccounts([...session.accounts.filter(a => a.id !== acc), account])
        editAccount(acc);
    }


    return <Box mt="10px">
        <Typography variant="h3" sx={{mb: "20px"}}>General App Perissions</Typography>
        {session.apps.filter(a => a.id !== PROTECTED.App.id).map((app, i) => {
            return <Box key={i} p={2} my=".5rem" border="1px solid" borderColor={colors.gray[400]} borderRadius="10px">
                <Typography variant="h4" fontWeight={600}>{app.name}</Typography>
                <Box display="flex" flex="wrap" gap={2}>
                    {app.permissions.root.map((p, i) => {
                        const checked = account?.permissions.includes(p.ident)

                        return <Box key={i} display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2}>
                            {p.name}
                            <Switch checked={checked} onChange={e => updatePermission(p.ident)} />
                        </Box>
                    })}
                </Box>
            </Box>
        })}
    </Box>
}