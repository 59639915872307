import { Box, Select, Switch, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useContext, useMemo, useState } from "react";
import TableWrapper from "../../../components/misc/tableWrapper";
import { PanelSessionContext } from "../../../components/sessionContext";
import { getColors } from "../../../theme";
import { Access, ConnectORPermissions, ConnectRootPermissions, PROTECTED } from "../../../utils/types";

export const ContainerDetail = ({con, acc, editAccount}:{con:number, acc:number, editAccount:(acc:number) => void}) => {
    const session = useContext(PanelSessionContext);
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    
    const account = useMemo(() => session.accounts.find(a => a.id === acc), [acc, session.accounts]);
    const container = useMemo(() => session.containers.find(c => c.id === con), [con, session.containers]);
    const usedAssets = useMemo(() => session.assets.filter(a => container?.assets.find(a2 => a2.id === a.id)), [container, session.assets]);

    const allowedToEdit = useMemo(() => {
        if (session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident)) return true;
        const conPermAssets = session.assets.filter(asset => asset.typeId === PROTECTED.AssetType.id);
        const asset = conPermAssets.find(a => (a.fields as any)[0] === container?.id);
        return session.logged?.access.find(access => access.containerId === PROTECTED.Container.id)?.permissions.find(p => p.asset === asset?.id)?.ident.includes(ConnectORPermissions.CONTAINER_ADMIN.ident) || false
    }, [account, container, session]);
    
    const [appId, setAppId] = useState<number>(container?.apps.length === 0 ? -1 : container?.apps[0].id!);

    const updateOrPermission = (assetId:number, ident:string) => {
        const access:Access = account!.access.find(a => a.containerId === con && a.appId === appId) || { appId: appId, containerId: con, permissions: [], conPermissions: []};
        const newAccount = {...account!, access: [...account?.access.filter(access => access.appId !== appId && access.containerId !== con) || []]};

        const pEntry = access.permissions.find(a => a.asset === assetId);
        if (!pEntry) {
            access.permissions.push({ asset: assetId, ident: [ident]});
            newAccount.access.push(access);
        } else if (pEntry.ident.includes(ident)) {
            pEntry.ident = pEntry.ident.filter(i => i !== ident);

            // This clean up is bad practice because it only appears in frontend so far but should be implemented in the backend aswell!
            if (pEntry.ident.length === 0) access.permissions = access.permissions.filter(a => a.asset !== assetId);
            if (access.permissions.length > 0 || access.conPermissions.length > 0) newAccount.access.push(access);
        } else {
            pEntry.ident.push(ident);
            newAccount.access.push(access);
        }
        session.setAccounts([...session.accounts.filter(a => a.id !== acc), newAccount])
        editAccount(acc);
    }

    
    const updateConPermission = (ident:string) => {
        const access:Access = account!.access.find(a => a.containerId === con && a.appId === appId) || { appId: appId, containerId: con, permissions: [], conPermissions: []};
        const newAccount = {...account!, access: [...account?.access.filter(access => access.appId !== appId && access.containerId !== con) || []]};

        if (access.conPermissions.includes(ident)) {
            access.conPermissions = access.conPermissions.filter(i => i !== ident);
            if (access.conPermissions.length !== 0 || access.permissions.length !== 0) newAccount.access.push(access);
        } else {
            access.conPermissions.push(ident);
            newAccount.access.push(access);
        }
        session.setAccounts([...session.accounts.filter(a => a.id !== acc), newAccount])
        editAccount(acc);
    }

    const app = useMemo(() => session.apps.find(a => a.id === appId), [appId, container]);
    const columns:any[] = [
        { field: 'id', headerName: "ID"},
        { field: "name", headerName: "Name", flex: 1},
        ...app?.permissions.or.map(p => {
            return { field: p.ident, headerName: p.name, renderCell: (params:any) => {
                const access:Access = account!.access.find(a => a.containerId === con && a.appId === appId) || { appId: appId, containerId: con, permissions: [], conPermissions: []}
                const checked = access.permissions.find(a => a.asset === params.row.id)?.ident.includes(p.ident) || false;
                return <>
                    <Switch disabled={!allowedToEdit} onChange={e => updateOrPermission(params.row.id, p.ident)} checked={checked} />
                </>
            }}
        }) || []
    ]
    
    if (appId === -1) {
        return <Box>
            There are no apps applied to this container!
        </Box>
    }
    
    return <Box>
        <Box display="flex" gap={2} alignItems={"center"}>
            <Typography variant="h4">Container Detail</Typography>
            <Select value={appId} onChange={(e) => setAppId(+e.target.value)} size="small" sx={{ minWidth: "120px"}}>
                {container?.apps.map((a, i) => {
                    return <option key={i} value={a.id}>{session.apps.find(a2 => a2.id === a.id)?.name}</option>
                })}
            </Select>
        </Box>
        <Box display="flex" gap={2} flexWrap="wrap">
            {app?.permissions.con.map((p, i) => {
                const access:Access = account!.access.find(a => a.containerId === con && a.appId === appId) || { appId: appId, containerId: con, permissions: [], conPermissions: []}
                const checked = access.conPermissions.includes(p.ident) || false;

                return <Box key={i} p={1} border="2px solid" borderColor={colors.gray[600]} borderRadius="15px" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    {p.name}
                    <Switch disabled={!allowedToEdit} checked={checked} onChange={e => updateConPermission(p.ident)} />
                </Box>
            })}
        </Box>
        <TableWrapper>
            <DataGrid rows={usedAssets} columns={columns} hideFooter />
        </TableWrapper>
    </Box>
}