import { Box, SxProps, useTheme } from "@mui/material";
import { FC, ReactElement } from "react";
import { getColors } from "../../theme";

const TableWrapper:FC<{children:ReactElement[]|ReactElement, sx?:SxProps}> = ({children, sx}:{sx?:SxProps, children:ReactElement[]|ReactElement}) => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return <Box m="10px 0 0 0" height="75vh" sx={{
        "& .MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-cell": {
            borderBottom: "none"
        },
        "& .name-column-cell": {
            color: colors.blueAccent[300]
        },
        "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.blueAccent[800],
            borderRadius: "20px 20px 0 0",
            borderBottom: "none"
        }, 
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400]
        }, 
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.blueAccent[800],
            borderRadius: "0 0 20px 20px",
        },
        "& .MuiCheckbox-root": {
            color: `${colors.blueAccent[200]} !important`
        }, ...sx
    }}>
        {children}
    </Box>
}

export default TableWrapper;