import { DarkModeOutlined, LightModeOutlined, NotificationsOutlined, PersonOutlined, SettingsOutlined } from "@mui/icons-material";
import { Box, IconButton, Popover, Switch, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, getColors } from "../../theme";
import { SettingsContext, useSettings } from "../../components/settingsContext";

const TopBar = () => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const settings = useContext(SettingsContext);

    const [openSettings, setOpenSettings] = useState<boolean>(false);

    return <>
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box>
                <Typography variant="h3" fontWeight={"bold"}>SEANET-CONNECT</Typography>
            </Box>
            <Box display="flex">
                <IconButton onClick={e => colorMode.toggleColorMode()}>
                    {theme.palette.mode === "dark" ? <DarkModeOutlined /> : <LightModeOutlined />}
                </IconButton>
                {/* <IconButton><NotificationsOutlined /></IconButton> */}
                <IconButton id="settingsTopBarBtn" onClick={e => setOpenSettings(!openSettings)}><SettingsOutlined /></IconButton>
                {/* <IconButton><PersonOutlined /></IconButton> */}
            </Box>
        </Box>

        <Popover open={openSettings}  onClose={() => setOpenSettings(false)} anchorEl={document.getElementById("settingsTopBarBtn")} anchorOrigin={{horizontal: "center", vertical: "bottom"}}>
            <Box sx={{p: "2rem", width: "20rem"}}>
                <Typography variant="h3" fontWeight={500} sx={{mb: "1rem"}}>Settings</Typography>
                <Box display="flex" flexDirection="column" gap="1rem">
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6">Development Mode</Typography>
                        <Switch checked={settings.devMode} onChange={e => settings.setDevMode(e.currentTarget.checked)}/>
                    </Box>
                </Box>
            </Box>
        </Popover>
    </>
}

export default TopBar;