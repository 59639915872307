import { Add, EditNote, Remove } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Switch, Typography } from "@mui/material";
import axios from "axios";
import { Field, Formik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import FormikSelect from "../../components/misc/formikSelect";
import { PanelSessionContext } from "../../components/sessionContext";
import { Container, PROTECTED } from "../../utils/types";
import { UtilityFunctions } from "../../utils/utilityFunctions";
import { addNotification } from "../global/notificationWrapper";

const validationSchema = yup.object().shape({
    id: yup.number().required("Object is required.").positive("Asset is required."),
});

export const ContainerAssetDialogs = {
    Create: ({containerId, appMode}:{containerId:number, appMode:number}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);
        const [assetType, setAssettype] = useState<number>(session.assetTypes.filter(a => a.id !== PROTECTED.AssetType.id)[0].id || 0);

        const container = useMemo(() => session.containers.find(c => c.id === containerId), [containerId, open]);
        const submit = (values:any) => {
            if (!container) {
                return addNotification({message: "Container not found.", severity: "error"});
            }

            const apps = [...container.apps, appMode ? { id: values.id } : null].filter(a => a);
            const assets = [...container.assets, !appMode ? { id: values.id} : null].filter(a => a);
            axios.post(`${process.env.REACT_APP_API_URL}/container/edit`, {...container, apps, assets}, {withCredentials: true}).then(res => {
                if (res.data.success) {
                    session.setContainers([...session.containers.filter(c => c.id !== containerId), res.data.response])
                    setOpen(false);
                    addNotification({message: "Container created successfully.", severity: "success"});
                } else {
                    console.error(res.data.error);
                    addNotification({message: "Failed to update Container.", severity: "error"});
                }
            });
        }

        const initialValues = {id: 0};
        return <>
        <Button onClick={e => setOpen(true)}><Add /></Button>
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Add an {appMode ? "App" : "Asset"}</DialogTitle>
            <DialogContent>
                <DialogContentText mb={"1rem"}>Select the {appMode ? "App" : "Asset"} that you want to add!</DialogContentText>
                <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                    {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                        <form onSubmit={handleSubmit}>
                            <Box display="flex" flexDirection="column" m="0 1rem" gap={2}>
                                <Box display="flex" gap={2}>
                                    {!appMode && <Select sx={{width: "50%"}} onChange={e => setAssettype(+e.target.value)} value={assetType}>
                                        {session.assetTypes.filter(at => at.id !== PROTECTED.AssetType.id).map((type, index) => <MenuItem key={index} value={type.id}>{type.name}</MenuItem>)}
                                    </Select>}
                                    <Field component={FormikSelect} name="id" type="number" label={`${appMode ? "App" : "Asset"} ID`} onBlur={handleBlur} onChange={handleChange}
                                     error={!!touched.id && !!errors.id}>
                                        {!appMode ? session.assets.filter(a => a.typeId === assetType && !container?.assets.find(a2 => a2.id === a.id))?.map((asset, index) => {
                                            return <MenuItem key={index} value={asset.id}>{asset.name}</MenuItem>
                                        }) : undefined}

                                        {appMode ? session.apps.filter(a => !container?.apps.find(a2 => a2.id === a.id) && a.id !== PROTECTED.App.id)?.map((app, index) => {
                                            return <MenuItem key={index} value={app.id}>{app.name}</MenuItem>
                                        }) : undefined}
                                    </Field>
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="center" mt="20px">
                                <Button type="submit" variant="contained">Submit</Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    </>
    },
    Delete: ({containerId, ids, appMode}:{containerId:number, ids:number[], appMode:number}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);

        const container = useMemo(() => session.containers.find(c => c.id === containerId), [containerId, open]);
        const submit = () => {
            if (!container) {
                return addNotification({ message: "Failed to retrieve container object", severity: "error"});
            }

            const apps = appMode ? container.apps.filter(a => !ids.includes(a.id)) : container.apps;
            const assets = !appMode ? container.assets.filter(a => !ids.includes(a.id)) : container.assets;
            axios.post(process.env.REACT_APP_API_URL + "/container/edit", {...container, apps, assets}, { withCredentials: true }).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    session.setContainers([...session.containers.filter(c => c.id !== containerId), res.data.response])
                    addNotification({message: "Asset types deleted", severity: "success"});
                } else {
                    console.log("Error deleting asset types ", res.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)} variant="contained"><Remove /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Remove Asset from Container</DialogTitle>
                <DialogContent>
                    <DialogContentText>Removing an asset from a container can effect permission access levels for accounts!</DialogContentText>
                    <Box display="flex" justifyContent="center" mt="20px" gap="20px">
                        <Button variant="contained" onClick={submit}>Yes</Button>
                        <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    }
}