import { ThemeOptions, createTheme } from "@mui/material";
import { createContext, useEffect, useMemo, useState } from "react";

export const getColors = (mode:"dark"|"light") => {
    switch (mode) {
        case "dark":
            return {
                gray: {
                    100: "#e0e0e0",
                    200: "#c2c2c2",
                    300: "#a3a3a3",
                    400: "#858585",
                    500: "#666666",
                    600: "#525252",
                    700: "#3d3d3d",
                    800: "#292929",
                    900: "#141414"
                },
                primary: {
                    100: "#d0d1d5",
                    200: "#a1a4ab",
                    300: "#727681",
                    400: "#1f2a40",
                    500: "#141b2d",
                    600: "#101624",
                    700: "#0c101b",
                    800: "#080b12",
                    900: "#040509"
                },
                greenAccent: {
                    100: "#b3ffc6",
                    200: "#86f18b",
                    300: "#66e675",
                    400: "#4bd965",
                    500: "#28a745",
                    600: "#208a38",
                    700: "#1a6e2e",
                    800: "#145223",
                    900: "#0e3818", 
                },
                redAccent: {
                    100: "#f8dcdb",
                    200: "#f1b9b7",
                    300: "#e99592",
                    400: "#e2726e",
                    500: "#db4f4a",
                    600: "#af3f3b",
                    700: "#832f2c",
                    800: "#58201e",
                    900: "#2c100f"
                },
                blueAccent: {
                    100: "#e1e2fe",
                    200: "#c3c6fd",
                    300: "#a4a9fc",
                    400: "#868dfb",
                    500: "#6870fa",
                    600: "#535ac8",
                    700: "#3e4396",
                    800: "#2a2d64",
                    900: "#151632",
                }
            }
        case "light":
            return {
                gray: {
                    100: "#141414",
                    200: "#292929",
                    300: "#3d3d3d",
                    400: "#525252",
                    500: "#666666",
                    600: "#858585",
                    700: "#a3a3a3",
                    800: "#c2c2c2",
                    900: "#e0e0e0",
                },
                primary: {
                    100: "#040509",
                    200: "#080b12",
                    300: "#0c101b",
                    400: "#f2f0f0",
                    500: "#dadde7",
                    600: "#434957",
                    700: "#727681",
                    800: "#a1a4ab",
                    900: "#d0d1d5",
                },
                greenAccent: {
                    100: "#E6FFEF",
                    200: "#9AEAAB",
                    300: "#74D589",
                    400: "#4EC067",
                    500: "#28A745",
                    600: "#24933E",
                    700: "#207F37",
                    800: "#1C6B30",
                    900: "#185729",
                },
                redAccent: {
                    100: "#2c100f",
                    200: "#58201e",
                    300: "#832f2c",
                    400: "#af3f3b",
                    500: "#db4f4a",
                    600: "#e2726e",
                    700: "#e99592",
                    800: "#f1b9b7",
                    900: "#f8dcdb",
                },
                blueAccent: {
                    100: "#151632",
                    200: "#2a2d64",
                    300: "#3e4396",
                    400: "#535ac8",
                    500: "#6870fa",
                    600: "#868dfb",
                    700: "#a4a9fc",
                    800: "#c3c6fd",
                    900: "#e1e2fe",
                }
            }
    }
}

export const themeSettings = (mode:"dark"|"light"):ThemeOptions => {
    const colors = getColors(mode);

    return {
        palette: {
            mode,
            ...(mode === "dark" ? {
                primary: {
                    main: colors.primary[500]
                }, 
                secondary: {
                    main: colors.greenAccent[500]
                },
                common: {
                    black: colors.gray[700],
                    white: colors.gray[100]
                },
                background: {
                    default: colors.primary[500]
                }
            } : {
                primary: {
                    main: colors.primary[100]
                },
                secondary: {
                    main: colors.greenAccent[500]
                }, 
                common: {
                    black: colors.gray[700],
                    white: colors.gray[100]
                },
                background: {
                    default: "#fcfcfc"
                }
            })
        },
        typography: {
            fontFamily: ["Source Sans 3", "sans-serif"].join(","),
            fontSize: 12,
            h1: { fontFamily: ["Source Sans 3", "sans-serif"].join(","), fontSize: 40 },
            h2: { fontFamily: ["Source Sans 3", "sans-serif"].join(","), fontSize: 32 },
            h3: { fontFamily: ["Source Sans 3", "sans-serif"].join(","), fontSize: 24 },
            h4: { fontFamily: ["Source Sans 3", "sans-serif"].join(","), fontSize: 20 },
            h5: { fontFamily: ["Source Sans 3", "sans-serif"].join(","), fontSize: 16 },
            h6: { fontFamily: ["Source Sans 3", "sans-serif"].join(","), fontSize: 14 },
        },
        components: {
            MuiCard: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.primary[500],
                    }
                }
            }, 
            MuiPopover: {
                styleOverrides: {
                    paper: { backgroundColor: colors.primary[500] }
                }
            }
        }
    }
}

// Colormode context
export const ColorModeContext = createContext({
    toggleColorMode: () => {}
});

export const useMode = () => {
    const [mode, setMode] = useState<"dark"|"light">(localStorage.getItem("theme") as any || "dark");

    const colorMode = useMemo(() => ({
        toggleColorMode: () => setMode((prev) => {
            const newMode = (prev === "dark" ? "light" : "dark");
            localStorage.setItem("theme", newMode);
            return newMode;
        }),
    }), [])

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return {theme, colorMode};
}