import { Alert, Slide, Snackbar, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { getColors } from "../../theme";

export type Notification = {
    severity: "error" | "warning" | "info" | "success";
    message: string;
}

export let addNotification:(not: Notification) => void;

const SHOW_DURATION = 3 * 1000;

const NotificationWrapper = () => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);
    const [nots, setNots] = useState<Notification[]>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setNots(nots => {
                if (nots.length === 0) return nots;

                return nots.slice(1);
            });
        }, SHOW_DURATION);

        return () => clearInterval(interval);
    }, []);

    addNotification = (not: Notification) => {
        setNots(nots => {
            return [...nots, not];
        });
    }

    return <>
        {nots.map((not, i) => {
            if (i > 10) return;

            return <Snackbar open key={i} 
            sx={{ marginBottom: (65 * i) + "px",}} 
            TransitionComponent={Slide}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}} 
            >
                <Alert severity={not.severity} variant="filled" sx={{width: "15rem"}}>
                    {not.message}
                </Alert>
            </Snackbar>
        })}
    </>
}

export default NotificationWrapper;