

// Account types
export type Account = {
    id: number;
    name: string;
    api?: string|boolean;
    email: string;
    permissions: string[];
    password?: string;
    accounts: number[];
    access: Access[];
    sessions: Session[];
}

export type Access = {
    containerId:number
    appId:number
    conPermissions:string[]
    permissions:{
        asset:number
        ident:string[]
    }[]
}

export type AppPermissions = {
    or:Permission[],
    con:Permission[],
    root:Permission[]
}

// Asset types
export type AssetType = {
    id:number;
    name:string;
    fields:Field[];
}

export type Permission = {
    ident:string;
    name:string;
}

export type AppWebFetch = {
    enabled: boolean, 
    lastFetch: number, 
    success: boolean
}

export interface Object {
    id:number
    name:string
}

export interface App extends Object {
    url:string
    token:string
    webFetch:boolean|AppWebFetch
    permissions:AppPermissions
}

export interface Asset extends Object {
    typeId:number
    fields:any[];
}

export type Field = {
    id?:number;
    type:number;
    name:string;
    required:boolean;
    options?:number|string[];
}

export const FieldTypes = {
    TEXT: {id: 1, name: "Text"},
    NUMBER: {id: 2, name: "Number"},
    CHECKFIELD: {id: 3, name: "Checkfield"},
    SELECTION: {id: 4, name: "Selection (Not implemented)"},
    ASSET: {id: 5, name: "Asset"}
}


// Container
export type Container = {
    id?:number;
    name:string;
    apps: {
        id:number
    }[];
    assets: {
        id:number
    }[]
}

// Utils
export type Session = {
    id:string;
    accountId:number;
    expire:number;
    created:number;
}

export const ConnectRootArray = [
    { ident: "seanet.connect.root", name: "Root" }
]

export const ConnectORArray = [
    { ident: "seanet.connect.container.admin", name: "Admin" }
]

export const ConnectRootPermissions = {
    ROOT: ConnectRootArray[0]
}

export const ConnectORPermissions = {
    CONTAINER_ADMIN: ConnectORArray[0]
}

export const PROTECTED = {
    AssetType: {
        id: 1,
        name: "Container"
    },
    Container: {
        id: 1,
        name: "Container Access"
    },
    App: {
        id: 1,
        name: "Connect"
    }
}