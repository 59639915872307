import { Select } from "@mui/material";
import { FC, ReactElement } from "react";

type Props = {
    children: ReactElement[];
    form: any; // Formik actually does not have a type for that
    field: any;
    error?:boolean;
}

const FormikSelect:FC<Props> = ({ error, children, form, field}:Props) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return <Select error={error} name={name} value={value} onChange={e => setFieldValue(name, e.target.value)} sx={{width: "100%"}}>
        {children}
    </Select>
}

export default FormikSelect;
