import { useContext, useMemo, useState } from "react"
import { PanelSessionContext } from "../../../components/sessionContext"
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, Switch, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { Access, Account, App, ConnectORPermissions, ConnectRootPermissions, Container, PROTECTED } from "../../../utils/types";
import { addNotification } from "../../global/notificationWrapper";
import { access } from "fs";
import { UtilityFunctions } from "../../../utils/utilityFunctions";
import { DataGrid } from "@mui/x-data-grid";
import { TabPanel } from "../../../components/misc/tabPanel";
import TabsWrapper from "../../../components/misc/tabsWrapper";
import axios from "axios";
import TableWrapper from "../../../components/misc/tableWrapper";
import { Add, AutoAwesome } from "@mui/icons-material";

const AccountCreationWizzard = () => {
    const session = useContext(PanelSessionContext);

    const [state, setState] = useState(0);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [permissions, setPermissions] = useState<string[]>([]);
    const [access, setAccess] = useState<Access[]>([]);
    const [api, setApi] = useState<boolean>(false);
    const [search, setSearch] = useState("");

    // last page
    const [selectedContainer, setSelectedContainer] = useState<number>();
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedApp, setSelectedApp] = useState<number>();

    const reset = () => {
        setUsername("");
        setPassword("");
        setEmail("");
        setPermissions([]);
        setAccess([]);
        setApi(false);
        setState(0);

        setSelectedApp(undefined);
        setSelectedContainer(undefined);
    }

    const submit = async () => {
        switch (state) {
            case 1:
                if (username.length < 3 || username.length > 32) return addNotification({ severity: "warning", message: "The username must be between 3 and 32 characters long"})
                if (password.length < 8 || password.length > 128) return addNotification({ severity: "warning", message: "The password must be between 8 and 128 characters long"})

                setState(state + 1);
                break;
            case 2:
                if (permissions.includes(ConnectRootPermissions.ROOT.ident)) return addNotification({ severity: "warning", message: "You are not allowed to create an account with root permissions"});

                setState(state + 1);
                break;
            case 3:
                
                setState(state + (session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident) ? 1 : 2 ));
                setSearch("");
                break;
            case 4:
                setState(state + 1);
                break;
            case 5:
                // TODO perhaps turn this into one api request
                const baseAccount = { name: username, password, email, permissions};
                const res = await axios.post(process.env.REACT_APP_API_URL + "/account/create", baseAccount, { withCredentials: true });
                if (!res.data.success) {
                    addNotification({ severity: "error", message: "Failed to create account! Please try again later."});
                    return console.log(res.data);
                }

                const created = { id: res.data.response.id, name: username, api, permissions, email, password, access, accounts: [], sessions: [] } as Account;
                const updateRes = await axios.post(process.env.REACT_APP_API_URL + "/account/edit", created, { withCredentials: true });
                if (!res.data.success) {
                    addNotification({ severity: "error", message: "Failed to create account! Please try again later."});
                    return console.log("Error in edit requiest: ", res.data);
                }

                session.setAccounts([...session.accounts, {...created, api: res.data.response.api}])
                addNotification({ severity: "success", message: "Successfully created account!"});
                reset();
                break;
        }
    }


    const toggleConnectContainer = (id:number) => {
        const connectAccess = access.find(a => a.containerId === PROTECTED.Container.id && a.appId === PROTECTED.App.id) || 
        { containerId: PROTECTED.Container.id, appId: PROTECTED.App.id, permissions: [], conPermissions: [] };

        const containerAsset = session.assets.find(a => a.typeId === PROTECTED.AssetType.id && a.fields[0] === id);
        if (connectAccess.permissions.find(p => p.asset === containerAsset?.id)?.ident.includes(ConnectORPermissions.CONTAINER_ADMIN.ident)) {
            connectAccess.permissions = connectAccess.permissions.map((p) => {
                if (p.asset === containerAsset?.id) {
                    p.ident = p.ident.filter(s => s !== ConnectORPermissions.CONTAINER_ADMIN.ident);
                }
                return p;
            }).filter(a => a.ident.length > 0);
        } else {
            const perm = connectAccess.permissions.find(p => p.asset === containerAsset?.id) || { asset: containerAsset?.id!, ident: [] };
            perm.ident.push(ConnectORPermissions.CONTAINER_ADMIN.ident);

            if (connectAccess.permissions.find(p => p.asset === containerAsset?.id)) {
                connectAccess.permissions = connectAccess.permissions.map((c) => {
                    if (c.asset === containerAsset?.id)
                        return perm;
                    return c;
                })
            } else {
                connectAccess.permissions.push(perm);
            }
        }
        setAccess([...access.filter(a => a.appId !== connectAccess.appId || a.containerId !== connectAccess.containerId), connectAccess].filter(a => a.permissions.length !== 0 || a.conPermissions.length !== 0));
    }

    const updateOrPermission = (assetId:number, ident:string) => {
        if (!selectedApp || !selectedContainer) return;
        const acc:Access = access.find(a => a.containerId === selectedContainer && a.appId === selectedApp) || { appId: selectedApp, containerId: selectedContainer, permissions: [], conPermissions: []};
        const newAccess = [...access.filter(access => access.appId !== selectedApp && access.containerId !== selectedContainer) || []];

        const pEntry = acc.permissions.find(a => a.asset === assetId);
        if (!pEntry) {
            acc.permissions.push({ asset: assetId, ident: [ident]});
            newAccess.push(acc);
        } else if (pEntry.ident.includes(ident)) {
            pEntry.ident = pEntry.ident.filter(i => i !== ident);

            // This clean up is bad practice because it only appears in frontend so far but should be implemented in the backend aswell!
            if (pEntry.ident.length === 0) acc.permissions = acc.permissions.filter(a => a.asset !== assetId);
            if (acc.permissions.length > 0 || acc.conPermissions.length > 0) newAccess.push(acc);
        } else {
            pEntry.ident.push(ident);
            newAccess.push(acc);
        }

        setAccess(newAccess);
    }

    const updateConPermission = (ident:string) => {
        if (!selectedApp || !selectedContainer) return;
        const acc:Access = access.find(a => a.containerId === selectedContainer && a.appId === selectedApp) || { appId: selectedApp, containerId: selectedContainer, permissions: [], conPermissions: []};
        const newAccess = [...access.filter(access => access.appId !== selectedApp && access.containerId !== selectedContainer) || []];

        if (acc.conPermissions.includes(ident)) {
            acc.conPermissions = acc.conPermissions.filter(i => i !== ident);
            if (acc.conPermissions.length !== 0 || acc.permissions.length !== 0) newAccess.push(acc);
        } else {
            acc.conPermissions.push(ident);
            newAccess.push(acc);
        }
        setAccess(newAccess);
    }

    const toggleRootPermission = (ident:string) => {
        if (permissions.includes(ident)) return setPermissions(permissions.filter(a => a !== ident));
        setPermissions([...permissions, ident])
    }

    const availableContaines = useMemo(() => {
        const user = session.logged;
        const acc = user?.access.find(c => c.containerId === PROTECTED.Container.id && c.appId === PROTECTED.App.id);
        return session.containers.filter((c) => {
            if (c.id === PROTECTED.Container.id) return false;
            if (user?.permissions.includes(ConnectRootPermissions.ROOT.ident)) return true;
            const asset = session.assets.find(a => a.typeId === PROTECTED.AssetType.id && a.fields[0] === c.id);
            if (acc?.permissions.find(p => p.asset === asset?.id)?.ident.includes(ConnectORPermissions.CONTAINER_ADMIN.ident)) return true;
        })
    }, []);

    const availableApps = useMemo(() => {
        if (!selectedContainer) return [];
        const con = session.containers.find(c => c.id === selectedContainer);

        return con?.apps.map(a => session.apps.find(a2 => a2.id === a.id) || { id: -1, name: "Unknown"});
    }, [selectedContainer]);

    const availableAssetsData = useMemo(() => {
        if (!selectedContainer || !selectedApp) return { assets: [], orPermissions: [], conPermissions: []};
        const account = session.logged;
        const container = session.containers.find(c => c.id === selectedContainer);
        const app = session.apps.find(a => a.id === selectedApp);
        
        const assets = container?.assets.map((asset) => session.assets.find(a => a.id == asset.id)!) || [];
        const conPermissions = app?.permissions.con.map((p, i) => {return {id: i, ...p} });
        const orPermissions = app?.permissions.or.map(p => {return {field: p.ident, headerName: p.name, renderCell: (params:any) => {
            const acc:Access = access.find(a => a.containerId === selectedContainer && a.appId === selectedApp) || { appId: selectedApp, containerId: selectedContainer, permissions: [], conPermissions: []}
            const checked = acc.permissions.find(a => a.asset === params.row.id)?.ident.includes(p.ident) || false;
            return <>
                <Switch onChange={e => updateOrPermission(params.row.id, p.ident)} checked={checked} />
            </>
        }}});

        return {assets, orPermissions, conPermissions};
    }, [access, selectedApp, selectedContainer]);


    return <>
        <Button onClick={e => setState(1)}><Add /></Button>
        <Dialog maxWidth="xl" open={state > 0} onClose={reset} >
            <DialogTitle>Account creation wizzard</DialogTitle>
            <DialogContent>
                <Box display="flex" alignItems="center" flexDirection="column">
                    {state === 1 ? <>
                        <Typography variant="body1">Enter basic data about the account that you want to create. 
                            Note that the username and password is required but email is not.
                        </Typography>

                        <Box display="flex" gap={2} mt="1rem" flexDirection="column">
                            <TextField value={username} onChange={e => setUsername(e.currentTarget.value)} placeholder="Username" />
                            <TextField value={email} onChange={e => setEmail(e.currentTarget.value)} placeholder="Mail"/>
                            <TextField value={password} onChange={e => setPassword(e.currentTarget.value)} type="password" placeholder="Password" /> 
                        </Box>
                    </> : state === 2 ? <>
                        <Box gap={2} display="flex" flexDirection="column">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="body2">Is this account going to be a Connect-Root Account?</Typography>
                                <Switch disabled={!session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident)} checked={permissions.includes(ConnectRootPermissions.ROOT.ident)} onChange={e => setPermissions(e.currentTarget.checked ? [...permissions, ConnectRootPermissions.ROOT.ident] : permissions.filter(p => p !== ConnectRootPermissions.ROOT.ident))} />
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="body2">Does this account need an extra api key?</Typography>
                                <Switch checked={api} onChange={e => setApi(!api)} />
                            </Box>
                        </Box>
                    </> : state === 3 ? <>
                        <Typography variant="body1" width={"300px"}>
                            Grant admin permissions for the following containers. This means that the account will be able to create other accounts and grant 
                            them permissions for the here selected containers.
                        </Typography>

                        <TextField sx={{ mt: "2rem", mb: "1rem"}} size="small" placeholder="Search" value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        <Box display="flex" flexDirection="column" minHeight="100px" maxHeight="500px" overflow="auto">
                            {session.containers.map((c, i) => {
                                if (c.id === PROTECTED.Container.id || (search.length > 0 && !c.name.toLowerCase().includes(search.toLowerCase()))) return null;
                                return <Box key={i} display="flex" alignItems="center" height="2rem" justifyContent="space-between">
                                    <Typography variant="body2">{c.name}</Typography>
                                    <Switch onChange={e => toggleConnectContainer(c.id!)} />
                                </Box>
                            })}
                            
                        </Box>    
                    </> : state === 4 ? <>
                        <Typography width={"300px"} mb="1rem"> 
                            Select the general permissions that your account is supposed to have. The permissions are not bond to any container and give capabilities to maintain the desired apps
                            without any asset relation.
                        </Typography>

                        <DataGrid 
                            columns={[
                                { field: "name", headerName: "App Name"},
                                { field: "permissions", headerName: "Permissions", renderCell: (params) => 
                                     <>
                                        {params.row.permissions.root.map((c, i) => 
                                            <Tooltip title={c.name}>
                                                <Switch checked={permissions.includes(c.ident)} onChange={e => toggleRootPermission(c.ident)} />
                                            </Tooltip>
                                        )}
                                    </>
                                }
                            ]}
                            rows={session.apps.filter(a => a.id !== PROTECTED.App.id && a.permissions.root.length > 0)}
                            hideFooter
                            sx={{ maxHeight: "600px", minHeight: "300px", minWidth: "300px"}}
                        /> 
                        
                    </> : state === 5 ? <>
                        <Typography variant="body1" mb="1rem">
                            Which container should be accessable to the account. Select the container, apps and assets
                        </Typography>

                        <Box display="flex" gap={2}>
                            <Box width="fit-content" mt="50px">
                                <DataGrid 
                                    columns={[ { field: "name", headerName: "Containers", flex: 1 } ]} 
                                    sx={{minWidth: "200px", maxHeight: "600px"}} 
                                    rows={availableContaines}
                                    hideFooter 
                                    rowSelectionModel={selectedContainer}
                                    onRowSelectionModelChange={e => setSelectedContainer((e as any[])[0])}
                                />
                            </Box>

                            <Box width="fit-content" mt="50px">
                                <DataGrid 
                                    columns={[ { field: "name", headerName: "Apps", flex: 1 } ]} 
                                    sx={{minWidth: "200px", maxHeight: "600px"}} 
                                    rows={availableApps || []}
                                    hideFooter 
                                    rowSelectionModel={selectedApp}
                                    onRowSelectionModelChange={e => setSelectedApp((e as any[])[0])}
                                />
                            </Box>

                            <Box>
                                <TabsWrapper >
                                    <Tabs value={tabValue} onChange={(e,v) => setTabValue(v)}>
                                        <Tab label="Asset Permissions" />
                                        <Tab label="Container Permissions" />
                                    </Tabs>
                                </TabsWrapper>

                                <TabPanel index={0} value={tabValue}>
                                    <Box width="fit-content" >
                                        <DataGrid 
                                            columns={[ 
                                                { field: "name", headerName: "Assets", width: 150},
                                                ...availableAssetsData.orPermissions! 
                                            ]} 
                                            sx={{minWidth: "450px", maxWidth: "500px", minHeight: "200px", maxHeight: "600px"}} 
                                            rows={availableAssetsData.assets || []}
                                            hideFooter 
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel index={1} value={tabValue}>
                                    <Box width="fit-content">
                                        <DataGrid 
                                            columns={[
                                                { field: "name", headerName: "Permission", width: 350},
                                                { field: "checked", headerName: "Enabled", renderCell: (params:any) => {
                                                    const acc:Access = access.find(a => a.containerId === selectedContainer && a.appId === selectedApp) || { appId: selectedApp!, containerId: selectedContainer!, permissions: [], conPermissions: []}
                                                    const checked = acc.conPermissions.includes(params.row.ident) || false;
                                                    return <Switch onChange={e => updateConPermission(params.row.ident)} checked={checked} />
                                                }}
                                            ]}
                                            sx={{minWidth: "500px", maxWidth: "500px", minHeight: "200px", maxHeight: "600px"}} 
                                            rows={availableAssetsData.conPermissions || []}
                                            hideFooter
                                        />
                                    </Box>
                                </TabPanel>
                            </Box>
                            

                        </Box>
                        
                    </> : undefined}
                        
                    <Box display="flex" mt="1rem" justifyContent="end">
                        <ButtonGroup variant="contained">
                            <Button color="inherit" onClick={reset}>Cancel</Button>
                            <Button onClick={submit}>Continue</Button>
                        </ButtonGroup>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}

export default AccountCreationWizzard;