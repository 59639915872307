import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { getColors } from "../../theme";

const Header:FC<{title:string, subtitle:string}> = ({title, subtitle}:{title:string, subtitle:string}) => {
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    return <Box mb="30px">
        <Typography variant="h2" color={colors.gray[100]} fontWeight="bold" sx={{mb: "5px"}}>{title}</Typography>
        <Typography variant="h5" color={colors.blueAccent[200]}>{subtitle}</Typography>
    </Box>
}

export default Header;