import { Add, EditNote, Remove } from "@mui/icons-material"
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Switch, TextField, Typography } from "@mui/material"
import axios from "axios"
import { Formik } from "formik"
import { useContext, useMemo, useState } from "react"
import * as yup from "yup"
import { PanelSessionContext } from "../../components/sessionContext"
import { Asset, AssetType, FieldTypes } from "../../utils/types"
import { addNotification } from "../global/notificationWrapper"

export const AssetDialog = {
    Create: ({type}:{type:number}) => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState<boolean>(false);
        const [initialValues, setInitialValues] = useState<any>({});
         
        const assetType = useMemo(() => session.assetTypes.find(a => a.id === type), [type]);
        const validationSchema = useMemo(() => {
            const updateInitial:any = {
                name: "",
            }
            const validation:any = {
                name: yup.string().required("Asset requires a name").notOneOf(session.assets.map(a => a.name), "A asset with that name already exists"),
            }

            assetType?.fields.forEach((el) => {
                updateInitial[el.id!] = "";
                switch (el.type) {
                    case FieldTypes.NUMBER.id:
                    case FieldTypes.SELECTION.id:
                    case FieldTypes.ASSET.id:
                        validation[el.id!] = yup.number();
                        break;
                    case FieldTypes.CHECKFIELD.id:
                        validation[el.id!] = yup.boolean();
                        updateInitial[el.id!] = false;
                        break;
                    default: 
                        validation[el.id!] = yup.string();
                }

                if (el.required) {
                    validation[el.id!] = validation[el.id!].required("This field is required!");
                }
            });
            
            setInitialValues(updateInitial);
            return yup.object().shape(validation);
        }, [type]);

        const submit = (values:AssetType|any) => {
            const name = values.name;
            delete values.name;

            axios.post(`${process.env.REACT_APP_API_URL}/assets/create`, { name, fields: values, type }, {withCredentials: true}).then(res => {
                console.log(res.data);
                if (res.data.success) {
                    setOpen(false);
                    const asset = { ...values, id: res.data.response, name }
                    session.setAssets([...session.assets, res.data.response]);
                    addNotification({ message: "Asset created!", severity: "success"})
                } else {
                    console.error(res.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)}><Add /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create a {assetType?.name} asset!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill out the form to create a new {assetType?.name}
                    </DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}:any) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name || ""} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    {assetType?.fields.map((field, index) => {
                                        // TODO handle other types than text and number
                                        if (field.type === FieldTypes.TEXT.id || field.type === FieldTypes.NUMBER.id) {
                                            return <TextField key={index} variant="filled" type={Object.values(FieldTypes).find(f => field.type === f.id)?.name} label={field.name} onBlur={handleBlur} onChange={handleChange}
                                                value={values[field.id!]} name={field.id + ""} error={!!touched[field.id!] && !!errors[field.id!]} helperText={touched[field.id!] && errors[field.id!]}
                                                sx={{borderRadius: "10px", margin: "5px 0"}} />
                                        } else if (field.type === FieldTypes.CHECKFIELD.id) {
                                            return <Box display="flex" justifyContent="space-between" alignItems="center" key={index}>
                                                <Typography>{field.name}</Typography>
                                                <Switch checked={values[field.id!]} onChange={(e, v) => handleChange({target: {name: field.id + "", value: v}})} />
                                            </Box>
                                        }  else if (field.type === FieldTypes.ASSET.id) {
                                            const targetType = session.assetTypes.find(a => a.id === field.options);
                                            if (!targetType) return <Typography key={index}>Failed to fetch Assettype of id: {field.options}</Typography>
                                            const assets = session.assets.filter(a => a.typeId === targetType.id);

                                            return <Box key={index} display="flex" m="10px 0" flexDirection="column">
                                                <Typography variant="h6">{targetType.name}</Typography>
                                                <Select value={values[field.id!]} onChange={(e, c:any) => handleChange({ target: { name: field.id + "", value: c.props.value}})}>
                                                    {assets.map((asset, i2) => {
                                                        return <MenuItem value={asset.id} key={i2}>{asset.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </Box>
                                        }


                                        return <></> 
                                    })}
                                </Box>
                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },
    Delete: ({ids}:{ids:number[]}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/assets/delete", {ids}, { withCredentials: true }).then(response => {
                if (response.data.success) {
                    setOpen(false);
                    session.setAssets(session.assets.filter(a => !ids.includes(a.id)));
                    addNotification({message: "Assets deleted", severity: "success"});
                } else {
                    console.log("Error deleting assets ", response.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)} variant="contained"><Remove /></Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Deleting Assets</DialogTitle>
                <DialogContent>
                    <DialogContentText>Be aware that the deletion of an asset will lead to a deletion of all its usages! You are about to delete {ids.length} Asset/s.</DialogContentText>
                    <Box display="flex" justifyContent="center" mt="20px" gap="20px">
                        <Button variant="contained" onClick={submit}>Yes</Button>
                        <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    },
    Edit: ({id}:{id:number}) => {
        const session = useContext(PanelSessionContext);

        const [open, setOpen] = useState<boolean>(false);
        const [initialValues, setInitialValues] = useState<any>({});
         
        const asset:Asset = useMemo(() => session.assets.find(a => a.id == id)!, [id]);
        const assetType = useMemo(() => session.assetTypes.find(a => a.id === asset.typeId), [id]);
        const validationSchema = useMemo(() => {
            const updateInitial:any = {
                name: asset?.name,
            }
            const validation:any = {
                name: yup.string().required("Asset requires a name"),
            }

            assetType?.fields.forEach((el) => {
                updateInitial[el.id!] = asset.fields[el.id!] || "";
                switch (el.type) {
                    case FieldTypes.NUMBER.id:
                    case FieldTypes.SELECTION.id:
                    case FieldTypes.ASSET.id:
                        validation[el.id!] = yup.number();
                        break;
                    case FieldTypes.CHECKFIELD.id:
                        validation[el.id!] = yup.boolean();
                        break;
                    default: 
                        validation[el.id!] = yup.string();
                }

                if (el.required) {
                    validation[el.id!] = validation[el.id!].required("This field is required!");
                }
            });
            
            setInitialValues(updateInitial);
            return yup.object().shape(validation);
        }, [id, open]);

        const submit = (values:AssetType|any) => {
            const name = values.name;
            delete values.name;
            
            axios.post(`${process.env.REACT_APP_API_URL}/assets/edit`, { name, fields: values, id }, {withCredentials: true}).then(res => {
                if (res.data.success) {
                    setOpen(false);
                    asset.fields = values;
                    asset.name = name;
                    session.setAssets([...session.assets.filter(a => a.id !== id), asset]);
                } else {
                    console.error(res.data.error);
                }
            });
        }

        return <>
            <Button onClick={e => setOpen(true)}><EditNote /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Create a {assetType?.name} asset!</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill out the form to create a new {assetType?.name}
                    </DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}:any) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    {assetType?.fields.map((field, index) => {
                                        // TODO handle other types than text and number
                                        if (field.type === FieldTypes.TEXT.id || field.type === FieldTypes.NUMBER.id) {
                                            return <TextField key={index} variant="filled" type={Object.values(FieldTypes).find(f => field.type === f.id)?.name} label={field.name} onBlur={handleBlur} onChange={handleChange}
                                                value={values[field.id!]} name={field.id + ""} error={!!touched[field.id!] && !!errors[field.id!]} helperText={touched[field.id!] && errors[field.id!]}
                                                sx={{borderRadius: "10px", margin: "5px 0"}} />
                                        } else if (field.type === FieldTypes.CHECKFIELD.id) {
                                            return <Box display="flex" justifyContent="space-between" alignItems="center" key={index}>
                                                <Typography>{field.name}</Typography>
                                                <Switch checked={values[field.id!]} onChange={(e, v) => handleChange({target: {name: field.id + "", value: v}})} />
                                            </Box>
                                        } else if (field.type === FieldTypes.ASSET.id) {
                                            const targetType = session.assetTypes.find(a => a.id === field.options);
                                            if (!targetType) return <Typography key={index}>Failed to fetch Assettype of id: {field.options}</Typography>
                                            const assets = session.assets.filter(a => a.typeId === targetType.id);

                                            return <Box key={index} display="flex" m="10px 0" flexDirection="column">
                                                <Typography variant="h6">{targetType.name}</Typography>
                                                <Select value={values[field.id!]} onChange={(e, c:any) => handleChange({ target: { name: field.id + "", value: c.props.value}})}>
                                                    {assets.map((asset, i2) => {
                                                        return <MenuItem value={asset.id} key={i2}>{asset.name}</MenuItem>
                                                    })}
                                                </Select>
                                            </Box>
                                        }

                                        return <></> 
                                    })}
                                </Box>
                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },
}