import { Box, useTheme } from "@mui/material";
import { useContext } from "react";
import { PanelSessionContext } from "../../components/sessionContext";
import { getColors } from "../../theme";
import GlobalStats from "./globalStats";

const DashboardPage = () => {
    const session = useContext(PanelSessionContext);
    const theme = useTheme();
    const colors = getColors(theme.palette.mode);

    

    return <Box m="20px" display="flex" flexDirection="column" gap={2}>
        <GlobalStats />
    </Box>
}

export default DashboardPage;