import { Box, Button, ButtonGroup, MenuItem, Select, TextField } from "@mui/material";
import { ContainerAssetDialogs } from "../containers/containerAssetDialogs";
import { AssetTypeDialogs } from "./assetTypeDialogs";
import { useContext, useEffect, useMemo, useState } from "react";
import { Field, FieldTypes } from "../../utils/types";
import { Add, FileDownload, Remove, Save } from "@mui/icons-material";
import TableWrapper from "../../components/misc/tableWrapper";
import { DataGrid } from "@mui/x-data-grid";
import { PanelSession, PanelSessionContext } from "../../components/sessionContext";
import axios from "axios";
import { addNotification } from "../global/notificationWrapper";

const fieldColumns = (fields:Field[], setFields:(fields:Field[]) => void, session:PanelSession) => [
    { field: "name", headerName: "Name", type: "string", editable: true},
    { field: "type", headerName: "Type", type: "singleSelect", valueOptions: Object.entries(FieldTypes).map(([key, value]) => { return {value: value.id, label: value.name}}), editable: true},
    { field: "options", headerName: "Options", type: "singleSelect", renderCell: (params:any) => {
        if (params.row.type === FieldTypes.ASSET.id) {
            return <Select value={params.row.options || -1} onChange={e => {
                params.row.options = e.target.value;
                setFields(fields.map(f => f.id === params.row.id ? params.row : f));    
                }}>
                <MenuItem value={-1}>-</MenuItem>
                {session.assetTypes.map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)}
            </Select>
        }
        return <></>
    }},
    { field: "required", headerName: "Required", type: "boolean", editable: true },
]

const AssetTypeEditor = ({id}:{id:number}) => {
    const session = useContext(PanelSessionContext);

    const [displayName, setDisplayName] = useState<string>("");
    const [selection, setSelection] = useState<number[]>([]);
    const [fields, setFields] = useState<Field[]>([]);
    
    const assetType = useMemo(() => session.assetTypes.find(a => a.id === id), [id]);
    const fieldId = useMemo(() => (fields.map(f => f.id).sort((a, b) => b! - a!)[0] || 0) + 1, [fields]);
    useEffect(() => {
        if (assetType) {
            setFields(assetType.fields)
            setDisplayName(assetType.name);
        }

    }, [assetType]);

    const save = () => {
        if (displayName == "") return addNotification({ message: "Enter a name!", severity: "error"})
        
        axios.post(process.env.REACT_APP_API_URL + "/assetType/edit", {id, name: displayName, fields}, { withCredentials: true }).then(response => {
            if (response.data.success) {
                session.setAssetTypes(session.assetTypes.map(s => {
                    if (s.id === id) return response.data.response;
                    return s;
                }));
                addNotification({message: "Service edited", severity: "success"});
            } else {
                console.log("Error editing service " + response.data.error);
            }
        });
    }

    return <Box>
        <Box display="flex" flexDirection="column">
            <ButtonGroup variant="contained" sx={{width: "fit-content"}}>
                <Button onClick={() => setFields([...fields, {id: fieldId, name: "New Field", type: FieldTypes.TEXT.id, required: false}])}><Add /></Button>
                <Button onClick={() => setFields(fields.filter(f => !selection.includes(f.id!)))}><Remove /></Button>
                <Button onClick={save}><Save /></Button>
            </ButtonGroup>
            <TextField sx={{marginTop: "1rem"}} value={displayName} onChange={e => setDisplayName(e.currentTarget.value)} placeholder="Name" />
            <TableWrapper>
                <DataGrid hideFooter columns={fieldColumns(fields, setFields, session)} rows={fields} checkboxSelection sx={{minHeight: "400px", overflow: "auto"}}
                onRowSelectionModelChange={e => setSelection(e as number[])} rowSelectionModel={selection} processRowUpdate={(newRow, oldRow) => {
                    setFields(fields.map(f => f.id === oldRow.id ? newRow : f))
                    return newRow;
                }} />
            </TableWrapper>
        </Box>
    </Box>
}

export default AssetTypeEditor;