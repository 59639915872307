import { useContext, useState } from "react";
import { PanelSessionContext } from "../../components/sessionContext";
import { Badge, Box, Button, Card, Chip, Grid, Popper, Typography, useTheme } from "@mui/material";
import Header from "../../components/misc/header";
import { Person2 } from "@mui/icons-material";
import { ConnectRootArray } from "../../utils/types";
import { connect } from "http2";
import { ProfileModals } from "./profileDialogs";
import { getColors } from "../../theme";

const ProfilePage = () => {
    const session = useContext(PanelSessionContext);
    const theme = useTheme();
    const colors = getColors(theme.palette.mode)

    const [showApiKey, setShowApi] = useState(false);

    return <Box m="20px" display="flex" flexDirection="column" gap={2}> 
        <Header title="Profile" subtitle="Customize your user experience." />
        <Grid container spacing={2} width="100%">
            <Grid item xs={8}>
                <Card sx={{p: "1.5rem", display: "flex", flexDirection: "column"}}>
                    <Person2 sx={{transform: "scale(2)", ml: "5px"}} />
                    <Box my="1rem">
                        <Typography variant="h3" fontWeight={600} >{session.logged!.name}</Typography>
                        <Typography variant="body1">{session.logged?.sessions.length} Session/s</Typography>
                    </Box>
                    
                    <Box display="flex" flexWrap="wrap" gap={1}  >
                        {session.logged?.permissions.map((val, i) => {
                            const app = session.apps.find(app => app.permissions.root.find(p => p.ident === val));
                            return <Chip key={i} label={app ? app.name + " - " + app.permissions.root.find(p => p.ident === val)?.name : ConnectRootArray.find(d => d.ident === val)?.name} sx={{ width: "fit-content"}} />
                        })}
                    </Box>

                    {session.logged?.access.filter(acc => acc.conPermissions.length > 0).map((acc, i) => {
                        const app = session.apps.find(a => a.id === acc.appId);
                        const container = session.containers.find(c => c.id === acc.containerId);

                        return <Box my="1rem">
                            <Typography variant="h2" >{app?.name}</Typography>
                            {acc.conPermissions.map((p, i) => {
                                const name = app?.permissions.con.find(pe => pe.ident === p)?.name;

                                return <Chip label={container?.name + " - " + (name || p)} /> 
                            })}
                        </Box>
                    })}

                    {session.logged?.api && <> 
                        <Button sx={{mt: "1rem"}} onClick={e => setShowApi(!showApiKey)} id="api-button" variant="contained">Show Api Key</Button>
                        <Popper open={showApiKey} anchorEl={document.getElementById("api-button")}>
                            <Box mt="1rem " sx={{ background: colors.gray[800]}} p="1rem" borderRadius="10px">
                                {session.logged?.api}
                            </Box>
                        </Popper>
                    </>}
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card sx={{p: "1.5rem"}}>
                    <Typography variant="h3" fontWeight={600} my="1rem">Setup</Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <ProfileModals.ChangePassword />
                        <ProfileModals.ClearSessions />
                    </Box>
                </Card>
            </Grid>
        </Grid>
    </Box>
}

export default ProfilePage;