import { createContext, useMemo, useState } from "react";
import { Account, App, Asset, AssetType, Container } from "../utils/types";

export type PanelSession = {
    logged: Account | undefined;
    accounts: Account[];
    assetTypes: AssetType[];
    assets: Asset[];
    apps: App[];
    containers: Container[];

    setLogged: (account: Account) => void;
    setAccounts: (users: Account[]) => void;
    setApps: (apps: App[]) => void;
    setAssetTypes: (services: AssetType[]) => void;
    setAssets: (assets: Asset[]) => void;
    setContainers: (containers: Container[]) => void;
}

export const PanelSessionContext = createContext<PanelSession>({
    logged: undefined,
    accounts: [],
    assetTypes: [],
    assets: [],
    containers: [],
    apps: [],

    setLogged: () => {},
    setAccounts: () => {},
    setAssetTypes: () => {},
    setAssets: () => {},
    setContainers: () => {},
    setApps: () => {}
});

export const usePanelSession = () => {
    const [logged, setLogged] = useState<Account | undefined>(undefined);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [assetTypes, setAssetTypes] = useState<AssetType[]>([]);
    const [assets, setAssets] = useState<Asset[]>([]);
    const [apps, setApps] = useState<App[]>([]);
    const [containers, setContainers] = useState<Container[]>([]);

    const ctxFunctions = useMemo(() => ({
        setLogged: (account: Account) => setLogged(account),
        setAccounts: (users: Account[]) => setAccounts(users),
        setAssetTypes: (services: AssetType[]) => setAssetTypes(services),
        setAssets: (assets: Asset[]) => setAssets(assets),
        setContainers: (containers: Container[]) => setContainers(containers),
        setApps: (apps: App[]) => setApps(apps),
    }), []);

    return {logged, accounts, assetTypes, assets, apps, containers, ...ctxFunctions};
}
