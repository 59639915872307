import { createContext, useMemo, useState } from "react"

export type Settings = {
    devMode:boolean

    setDevMode:(state:boolean) => void 
}

export const SettingsContext = createContext<Settings>({
    devMode: false,

    setDevMode: () => {}
})



export const useSettings = () => {
    const [devMode, setDevMode] = useState<boolean>(false);

    const ctxFunctions = useMemo(() => ({
        setDevMode: (state: boolean) => {
            setDevMode(state);
            localStorage.setItem("devMode", state + "");
        }
    }), [])

    return {devMode, ...ctxFunctions};
}