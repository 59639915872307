import { Add, EditNote, PostAdd, Remove } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Formik } from "formik";
import { useContext, useMemo, useState } from "react";
import * as yup from "yup";
import { PanelSessionContext } from "../../components/sessionContext";
import { Account, ConnectRootPermissions } from "../../utils/types";
import { addNotification } from "../global/notificationWrapper";

export const AccountDialogs = {
    CreateAccount: () => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);
        const [permissions, setPermissions] = useState<string[]>([]);
        
        // invalid id ist send only for coding comfort
        const submit = (values:Account) => {
            axios.post(process.env.REACT_APP_API_URL + "/account/create", {...values, permissions}, { withCredentials: true }).then(response => {
                setOpen(false);
                if (response.data.success) {
                    session.setAccounts([...session.accounts, response.data.response]);
                    addNotification({message: "Account created", severity: "success"});
                } else {
                    addNotification({message: "Error creating account", severity: "error"});
                    console.log("Error creating account ", response.data.error);
                }
            });
        }

        const initialValues:Account = {id: -1, name: "", sessions: [], email: "", permissions: [], access: [], accounts: [], password: ""};
        const validationSchema = yup.object({
            name: yup.string().required("Name is required").max(200, "Name must be at most 200 characters").notOneOf(session.accounts.map(a => a.name), "Account with that name already exists"),
            email: yup.string().email("E-Mail must be a valid E-Mail").max(200, "E-Mail must be at most 200 characters"),
            password: yup.string().required("Password is required").min(8, "Password must be at least 8 characters long").max(200, "Password must be at most 200 characters"),
        });

        const isRoot = useMemo(() => permissions.includes(ConnectRootPermissions.ROOT.ident), [permissions]);
        return <>
            <Button variant="contained" onClick={() => setOpen(true)} ><PostAdd /></Button>
            <Dialog fullWidth onClose={() => setOpen(false)} open={open}>
                <DialogTitle>Create a new Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill the form to create a new account.
                    </DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <TextField variant="filled" type="text" label="E-Mail" onBlur={handleBlur} onChange={handleChange}
                                        value={values.email} name="email" error={!!touched.email && !!errors.email} helperText={touched.email && errors.email}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <TextField variant="filled" type="password" label="Password" onBlur={handleBlur} onChange={handleChange}
                                        value={values.password} name="password" error={!!touched.password && !!errors.password} helperText={touched.password && errors.password}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    {session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident) ? <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">Root Account</Typography>
                                        <Checkbox onChange={e => setPermissions(isRoot ? permissions.filter(s => s !== ConnectRootPermissions.ROOT.ident) : [...permissions, ConnectRootPermissions.ROOT.ident])} name="root" checked={isRoot} />
                                    </Box> : <></>}
                                </Box>
                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },
    DeleteAccount: ({ids}:{ids:number[]}) => {
        const [open, setOpen] = useState(false);
        const session = useContext(PanelSessionContext);

        const submit = () => {
            axios.post(process.env.REACT_APP_API_URL + "/account/delete", {ids}, { withCredentials: true }).then(response => {
                setOpen(false);
                if (response.data.success) {
                    addNotification({message: "Account deleted", severity: "success"});
                    session.setAccounts(session.accounts.filter((user:Account) => !ids.includes(user.id)));
                } else {
                    addNotification({message: "Error deleting account", severity: "error"});
                    console.log("Error deleting account " + response.data.error);
                }
            });
        }

        return <>
            <Button variant="contained" onClick={() => setOpen(true)} ><Remove /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Delete Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {ids.length} account/s?
                    </DialogContentText>
                    <Box display="flex" justifyContent="center" mt="20px" gap="20px">
                        <Button variant="contained" onClick={submit}>Yes</Button>
                        <Button variant="contained" onClick={() => setOpen(false)}>No</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    },
    EditAccount: ({ids}:{ids:number[]}) => {
        const session = useContext(PanelSessionContext);
        const [open, setOpen] = useState(false);
        const [initialValues, setInitialValues] = useState<Account>({id: -1, name: "", sessions: [], email: "", permissions: [], access: [], accounts: [], password: ""});

        const submit = (values:Account) => {
            console.log(values);
            values.permissions = initialValues.permissions;
            axios.post(process.env.REACT_APP_API_URL + "/account/edit", {...values, id: ids[0]}, { withCredentials: true }).then((response:any) => {
                setOpen(false);
                if (response.data.success) {
                    session.setAccounts(session.accounts.map((user:Account) => {
                        if (user.id === ids[0]) {
                            return {...values, id: ids[0], api: response.data.response.api};
                        } else {
                            return user;
                        }
                    }));
                    addNotification({message: "Account edited", severity: "success"});
                } else {
                    addNotification({message: "Error editing account", severity: "error"});
                    console.log("Error editing account ", response.data.error);
                }
            });
        }

        const openDialog = () => {
            if (ids.length !== 1) {
                return addNotification({message: "You can only edit one account at a time", severity: "warning"});
            }

            const data = (session.accounts.find((user:Account) => user.id === ids[0]) || initialValues);
            setInitialValues({...data, password: ""});
            setOpen(true);
        }
        
        const validationSchema = yup.object({
            name: yup.string().required("Name is required").max(200, "Name must be at most 200 characters"),
            email: yup.string().email("E-Mail must be a valid E-Mail").max(200, "E-Mail must be at most 200 characters"),
            password: yup.lazy(value => value === "" || value === null ? yup.string().nullable() : yup.string().min(8, "Password must be at least 8 characters long").max(200, "Password must be at most 200 characters")),
        });

        const isRoot = useMemo(() => initialValues.permissions.includes(ConnectRootPermissions.ROOT.ident), [initialValues]);
        return <>
            <Button variant="contained" onClick={openDialog}><EditNote /></Button>
            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Edit Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change the values to edit the account.
                    </DialogContentText>
                    <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="flex" flexDirection="column" m="0 1rem">
                                    <TextField variant="filled" type="text" label="Name" onBlur={handleBlur} onChange={handleChange}
                                        value={values.name} name="name" error={!!touched.name && !!errors.name} helperText={touched.name && errors.name}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <TextField variant="filled" type="text" label="E-Mail" onBlur={handleBlur} onChange={handleChange}
                                        value={values.email} name="email" error={!!touched.email && !!errors.email} helperText={touched.email && errors.email}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    <TextField variant="filled" type="password" label="Password (unchanged)" onBlur={handleBlur} onChange={handleChange}
                                        value={values.password} name="password" error={!!touched.password && !!errors.password} helperText={touched.password && errors.password}
                                        sx={{borderRadius: "10px", margin: "5px 0"}} />

                                    {session.logged?.permissions.includes(ConnectRootPermissions.ROOT.ident) ? <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">Root Account</Typography>
                                        <Checkbox onChange={e => setInitialValues({...initialValues, permissions: isRoot ? initialValues.permissions.filter(s => s !== ConnectRootPermissions.ROOT.ident) : [...initialValues.permissions, ConnectRootPermissions.ROOT.ident]})} name="root" checked={isRoot} />
                                    </Box> : <></>}

                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">API Access</Typography>
                                        <Checkbox onChange={e => handleChange({ target: { id: "api", value: values.api ? undefined : "1"}})} checked={!!values.api} />
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="center" mt="20px">
                                    <Button type="submit" variant="contained">Submit</Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    },

}